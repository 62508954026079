import React from "react";
import { createUseStyles } from "react-jss";
import SectionHeader from "./SectionHeader";
import BigPageHeading from "./BigPageHeading";
import { motion } from "framer-motion";
import BackgroundText from "./BackgroundText";
import { Fragment } from "react";
import testImage from "../testimage.png";
import testImageUncropped from "../pics/laptops3.png";
import designPic from "../pics/sphere.svg";
import programmingPic from "../pics/LaptopBlue.png";
import deliveryPic from "../pics/cloud.png";
import techStackPic from "../pics/serverroom.png";
import keyPic from "../pics/KeyBlueRotated.png";
import servicesData from "../ServicesData";
import { Link } from "react-router-dom";
import SingleServicePreviewServicesPage from "./SingleServicePreviewServicesPage";
import ServicesPageHeading from "./ServicesPageHeading.js";
import Button from "./Button";
import ProgrammingLanguageContainer from "./ProgrammingLanguageContainer";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "calc(187px + 2em)",
    color: theme.colorText,
    width: "100%",
    overflow: "hidden",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
    },
  },
  mainContentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    maxWidth: "1750px",
  },
  heroImageTextContainer: {
    fontFamily: "Bebas",
    display: "flex",
    flexDirection: "column",
    // paddingBottom: "8em",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "100%",
    width: "25%",
  },
  listItem: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "2.5rem",
    color: theme.colorPrimary,
    // textShadow: `2px 2px ${theme.colorBackground}`,
    // textStroke: `1px ${theme.colorBackground}`,
    textAlign: "right",
    borderBottom: `4px solid transparent`,
    transition: "border .25s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      // fontSize: "3rem",
      borderBottom: `4px solid ${theme.colorText}`,
    },
  },
  listTitle: {
    fontSize: "3rem",
    color: theme.colorText,
    borderBottom: `4px solid ${theme.colorText}`,
    textShadow: `2px 2px ${theme.colorBackground}`,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    // height: "100%",
  },
  innerSection: {
    padding: "1em",
    paddingTop: "2em",
    paddingBottom: "2em",
    background: "#010911",
    // borderBottom: "1px solid white",
  },
  innerSectionHeading: {
    fontFamily: "Bebas",
    fontSize: "2rem",
    color: theme.colorPrimary,
  },
  innerSectionParagraph: {
    fontFamily: "Source Sans",
    color: theme.colorText,
    fontSize: "1.75rem",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    // gap: "1em",
  },
  paragraph: {
    fontFamily: "Source Sans",
    fontSize: "2rem",
    color: theme.colorText,
    marginTop: "1em",
    marginBottom: "1em",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2em",
  },
  image: {
    width: "75%",
    objectFit: "contain",
    maxHeight: "900px",
  },
  bottomContainer: {
    marginBottom: "2em",
  },
  topContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "flex-start",
    "@media(max-width:750px)": {
      display: "none",
    }
  }
}));

function ServicesPage() {
  const classes = useStyles();

  return (
    <Fragment>
      <BackgroundText />
      <div className={classes.mainContainer}>
        <div className={classes.mainContentContainer}>
          <BigPageHeading heading="Boost Your Brand with Studio White Tiger's Web Design and Development Services" />
          <motion.div
            className={classes.topContainer}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 100 }}
            viewport={{ once: true }}
          >
            <motion.img
              className={classes.image}
              src={testImageUncropped}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              transition={{duration: 0.5}}
              viewport={{ once: true }}
              alt="Laptop illustration"
            />
            <motion.div
              className={classes.heroImageTextContainer}
              initial={{ translateX: 100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              transition={{duration: 0.5}}
              viewport={{ once: true }}
            >
              <h3 className={classes.listTitle}>Design</h3>
              <ul className={classes.list}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[0]?.slug}`}
                    >
                      Website Design
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[1]?.slug}`}
                    >
                      Branding
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[2]?.slug}`}
                    >
                      Content Design
                    </Link>
                  </li>
                </div>
              </ul>
              <h3 className={classes.listTitle}>Development</h3>
              <ul className={classes.list}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[3]?.slug}`}
                    >
                      Custom Web Development
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[4]?.slug}`}
                    >
                      Internal Tools{" "}
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[5]?.slug}`}
                    >
                      Static Websites{" "}
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[6]?.slug}`}
                    >
                      Mobile Development{" "}
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[7]?.slug}`}
                    >
                      Backend Development{" "}
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[8]?.slug}`}
                    >
                      SEO Optimization{" "}
                    </Link>
                  </li>
                </div>
              </ul>
              <h3 className={classes.listTitle}>Delivery</h3>
              <ul className={classes.list}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {" "}
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[9]?.slug}`}
                    >
                      Deployment Options
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {" "}
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[10]?.slug}`}
                    >
                      Hosting and Maintenance
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {" "}
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[11]?.slug}`}
                    >
                      Full Service or A La Carte
                    </Link>
                  </li>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {" "}
                  <li
                    className={classes.listItem}
                  >
                    <Link
                      to={`/${servicesData[12]?.slug}`}
                    >
                      White-Labeling Services
                    </Link>
                  </li>
                </div>
              </ul>
            </motion.div>
          </motion.div>


          <div>
            <div>
              <ServicesPageHeading
                heading="Design"
                description="Blending creativity with data-driven insights, we excel at designing exceptional products and services. Our human-centered approach creates digital solutions that drive results and elevate your organization's value."
                image={designPic}
              />
              <motion.section
                className={classes.section}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 100 }}
                viewport={{ once: true }}
              >
                <SingleServicePreviewServicesPage
                  delay="1"
                  slug="website-design"
                  title="Website Design"
                  description="Our creative team specializes in crafting visually stunning and user-friendly website designs that capture the essence of your brand and effectively engage your target audience."
                />
                <SingleServicePreviewServicesPage
                  delay="2"
                  slug="branding"
                  title="Branding"
                  description="A memorable brand is essential for business recognition. We work closely with you to craft a distinctive brand that reflects your business's identity and values."
                />
                <SingleServicePreviewServicesPage
                  delay="3"
                  slug="content-design"
                  title="Content Design"
                  description="Beyond website design, we also create custom content for your website that complement your brand's online presence and enhance your marketing efforts."
                />
              </motion.section>

              <ServicesPageHeading
                heading="Development"
                description="Crafting robust web applications, we employ proven methodologies and adhere to industry best practices, ensuring seamless, maintainable software delivery for lasting success."
                image={programmingPic}
                height="200"
              />
              <motion.section
                className={classes.section}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 100 }}
                viewport={{ once: true }}
              >
                <SingleServicePreviewServicesPage
                  delay="1"
                  slug="custom-web-development"
                  title="Custom Web Development"
                  description="Our skilled developers are adept at creating bespoke web solutions using cutting-edge technologies like React and WordPress, tailored to your specific requirements."
                />
                <SingleServicePreviewServicesPage
                  delay="1.5"
                  slug="internal-tools"
                  title="Internal Tools"
                  description="Our experienced developers design and build custom internal tools that enhance productivity, automate processes, and improve decision-making within your organization."
                />
                <SingleServicePreviewServicesPage
                  delay="2"
                  slug="static-websites"
                  title="Static Websites"
                  description="For businesses seeking a simple yet impactful online presence, we design and develop static websites using HTML, CSS, and JavaScript."
                />
                <SingleServicePreviewServicesPage
                  delay="3"
                  slug="mobile-development"
                  title="Mobile Development"
                  description="We harness the power of React Native to create intuitive and engaging mobile applications that expand your reach and offer a seamless experience across devices."
                />
                <SingleServicePreviewServicesPage
                  delay="4"
                  slug="backend-development"
                  title="Backend Development"
                  description="Our expertise in Node.js and other backend languages allows us to develop robust backend systems, including API creation and integration, ensuring seamless communication between different components of your digital ecosystem."
                />
                <SingleServicePreviewServicesPage
                  delay="5"
                  slug="seo-optimization"
                  title="SEO Optimization"
                  description="We understand the importance of visibility in the digital world. Our team ensures that your website is optimized for search engines, improving your online ranking and driving organic traffic to your site."
                />
              </motion.section>

              <ServicesPageHeading
                heading="Delivery"
                description="Combining varied expertise, our dynamic teams collaborate to create top-notch digital experiences that fuel growth. From brand strategy to web development and digital marketing, we deliver comprehensive solutions for lasting success."
                image={deliveryPic}
              />
              <motion.section
                className={classes.section}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 100 }}
                viewport={{ once: true }}
              >
                <SingleServicePreviewServicesPage
                  delay="1"
                  title="Deployment Options"
                  slug="deployment-options"
                  description="Whether you prefer hosting your website on our secure servers or receiving the files directly, we offer flexible delivery options to suit your needs. Our team is experienced in using Docker for efficient and streamlined deployment."
                />
                <SingleServicePreviewServicesPage
                  delay="2"
                  slug="hosting-and-maintenance"
                  title="Hosting and Maintenance"
                  description="We provide reliable hosting solutions and ongoing maintenance services, ensuring that your website remains up-to-date and performs optimally at all times."
                />
                <SingleServicePreviewServicesPage
                  delay="3"
                  slug="full-service-a-la-carte"
                  title="Full Service or A La Carte"
                  description="Our goal is to cater to your specific requirements, whether you need an end-to-end solution or specific services. We offer the flexibility to choose the level of support that best fits your business needs."
                />
                <SingleServicePreviewServicesPage
                  delay="4"
                  slug="white-labeling-services"
                  title="White-Labeling Services"
                  description="For clients who require white-label solutions, we provide exceptional work that can be rebranded under your company's name, allowing you to maintain a consistent brand image and take full credit for the project."
                />
              </motion.section>
            </div>
          </div>


          <AboutUsPageHeroHeading
            heading="Powering Your Success with Cutting-Edge Technologies"
            subHeading="By staying at the forefront of technology and embracing the latest tools and programming languages, we create powerful web solutions that elevate your online presence and drive meaningful results."
            image={techStackPic}
            // width100
          />
          <ProgrammingLanguageContainer />
          <div className={classes.bottomContainer}>
            <SectionHeader header="Unlock the Full Potential of Your Business" />
            <AboutUsPageHeroHeading
              heading="Elevate Your Brand with Our Services"
              subHeading="Partner with our skilled team to develop a powerful, results-driven website that grows your business. Contact us now!"
              callToAction
              buttonText="Let's Talk"
              // rotate="320"
        
              image={keyPic}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ServicesPage;
