import React from "react";
import { createUseStyles } from "react-jss";
import Button from "./Button";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  outerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colorBackgroundDarker,
    width: "100%",
    minHeight: "70vh",
    zIndex: "0",
    padding: "1em"
  },
  mainContainer: {
    maxWidth: "1750px",
  },
  heading: {
    color: theme.colorPrimary,
    fontFamily: "Bebas",
    fontSize: "5rem",
    "@media(max-width:750px)": {
      fontSize: "3rem",
    }
  },
  subHeading: {
    color: theme.colorText,
    fontFamily: "Bebas",
    fontSize: "3rem",
    marginBottom: ".5em",
    "@media(max-width:750px)": {
      fontSize: "2rem",
    }
  },
  buttonContainer: {},
}));

function CallToActionSection() {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.outerContainer}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 100 }}
      transition={{ duration: 0.5 }}
      // viewport={{ once: true }}
    >
      <div className={classes.mainContainer}>
        <motion.h3
          className={classes.heading}
          initial={{ opacity: 0, translateY: -100 }}
          whileInView={{ opacity: 100, translateY: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Ready to Accelerate Your Online Success?
        </motion.h3>
        <motion.h4
          className={classes.subHeading}
          initial={{ opacity: 0, translateY: -100 }}
          whileInView={{ opacity: 100, translateY: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          viewport={{ once: true }}
        >
          Let our creative expertise transform your vision into a stunning,
          high-performance website that captivates your audience. Don't miss the
          opportunity to leave a lasting impression and drive results. Get in
          touch with us today, and let's turn your digital dreams into reality.
        </motion.h4>
        <motion.div
          initial={{ opacity: 0, translateX: -100 }}
          whileInView={{ opacity: 100, translateX: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          viewport={{ once: true }}
        >
          <Link className={classes.buttonContainer} to="/contact">
            <Button text="Get A Free Quote" color />
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default CallToActionSection;
