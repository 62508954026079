import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import Button from "./Button";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
  mainContainer: (props) => ({
    display: "flex",
    color: theme.colorText,
    flexDirection: props.reverse ? "row-reverse" : "row",
    justifyContent: "space-between",
    // alignItems: "center",
    width: "100%",
    // padding: "0rem 10rem"
    "@media(max-width:750px)": {
      flexDirection: props.reverse ? "column-reverse" : "column",
      gap: "1em",
    }
  }),
  heading: {
    fontFamily: "Bebas",
    fontSize: "4rem",
    "@media(max-width:750px)": {
      fontSize: "3rem",
    }
  },
  subHeading: {
    fontFamily: "Source Sans",
    fontSize: "2rem",
    marginBottom: ".5em",
  },
  leftContainer: (props) => ({
    width: props.width100 ? "100%" : "50%",
    "@media(max-width:750px)": {
      width: "100%"
    }
  }),
  image: (props) => ({
    display: "block",
    maxHeight: props.imageMaxHeight ?  props.imageMaxHeight : "350px",
    // paddingRight: "15rem",
    objectFit: "contain"
  }),
  outerImageContainer: {
    display: "flex",
    alignItems: "center",   
    justifyContent: "center",
  }
}));

function AboutUsPageHeroHeading(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.mainContainer}>
      <motion.div
        className={classes.leftContainer}
        initial={{ opacity: 0, translateY: -100 }}
        whileInView={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <h2 className={classes.heading}>{props.heading}</h2>
        <h3 className={classes.subHeading}>{props.subHeading}</h3>
        {props.callToAction ? (
          <Link to="/contact">
            <Button
              text={props.buttonText ? props.buttonText : "Get A Free Quote"}
              color
            />
          </Link>
        ) : (
          ""
        )}
      </motion.div>
      {props.width100 || props.noImage ? (
        ""
      ) : (
        <div className={classes.outerImageContainer}>
          <motion.img
            className={classes.image}
            src={props.image}
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.25 }}
            alt="Heading image"
          />
        </div>
      )}
    </div>
  );
}

export default AboutUsPageHeroHeading;
