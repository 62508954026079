import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    width: "100%",
    minHeight: "350px",
    alignItems: "center",
    justifyContent: "center",
    background: theme.colorBackgroundDarkerOpacity80,
    padding: "2em",
    flex: "1",
    // resize: "horizontal",
    flexGrow: "1",
    borderRadius: "30px",
    cursor: "pointer",
    gap: "1em",
    "&:hover $title": {
      transform: "rotateY(360deg)",
      borderBottom: `4px solid ${theme.colorPrimary}`,
      left: "0",
    },
    "&:hover $arrow": {
      opacity: "100",
      transform: "rotate(320deg)",
    },
  },
  outerContainer: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    flex: "1",
    // resize: "horizontal",
  },
  image: {
    height: "75px",
    maxWidth: "150px",
    filter: "grayscale()",
  },
  title: {
    position: "relative",
    display: "flex",
    transition: "all .75s ease-in-out",
    fontFamily: "Bebas",
    fontSize: "2rem",
    // left: ".6em",
    paddingBottom: ".15em",
    borderBottom: "4px solid transparent",
  },
  arrow: {
    transition: "all .5s ease-in-out",
    maxHeight: "20px",
    marginLeft: ".25em",
    opacity: "0",
  },
  link: {
    minWidth: "300px",
  },
}));

function ProgrammingLanguage(props) {
  const classes = useStyles();
  return (
    <a
      className={classes.mainContainer}
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={classes.image}
        src={props.image}
        alt="Logo of technology"
      />
      <h4 className={classes.title}>{props.title}</h4>
    </a>
  );
}

export default ProgrammingLanguage;
