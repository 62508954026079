import React from "react";
import { createUseStyles } from "react-jss";
import { useForm } from "react-hook-form";
import Button from "./Button";
import { motion } from "framer-motion";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: theme.colorText,
    marginBottom: "2em",
    gap: "2em",
    "@media (max-width:750px)": {
      flexDirection: "column",
    },
  },
  imageContainer: {
    position: "relative",
    overflow: "hidden",
    zIndex: "0",
    height: "100%",
    minHeight: "690px",
    width: "50%",
    objectFit: "cover",
    // backgroundImage: "))",
    // backgroundSize: "cover",
    // backgroundPositionY: "-100px",
    "@media (max-width:750px)": {
      width: "100%",
    },
  },
  image: {
    opacity: "75%",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    width: "100%",
    color: "white",
  },
  halfWidth: {
    width: "50%",
    height: "50px",
    fontSize: "2rem",
    color: "white",
    // borderBottom: `4px solid ${theme.colorPrimary}`,
    background: theme.colorBackground,
    // marginRight: "4em"
  },
  marginRight: {
    // marginRight: "3em",
  },
  fullWidth: {
    fontSize: "2rem",
    // width: "100%",
    height: "50px",
    color: "white",
    border: "none",
    borderBottom: `4px solid ${theme.colorPrimary}`,
    background: theme.colorBackground,
    "&:focused &:active": {
      border: "none",
    },
  },
  nameCompanyContainer: {
    display: "flex",
    gap: "1em",
    justifyContent: "space-between",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
  heroContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  heroLeftContainer: {
    width: "100%",
    fontFamily: "Bebas",
    fontSize: "clamp(2rem, 10px, 5rem)",
  },
  heroRightContainer: {
    width: "100%",
    display: "flex",
    fontFamily: "Source Sans",
    fontSize: "2rem",
    alignItems: "center",
    maxWidth: "50%",
  },
  messageContainer: {
    fontSize: "2rem",
    minHeight: "400px",
    background: theme.colorBackground,
    color: "white",
  },
  fullNameContainer: {
    color: "white",
    width: "100%",
    fontSize: "2rem",
    height: "50px",
    background: "transparent",
  },
  imageTextContainer: {
    fontFamily: "Bebas",
    position: "absolute",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "4em",
    paddingBottom: "8em",
    height: "100%",
    width: "100%",
    zIndex: "10",
  },
  imageText: {
    fontSize: "3rem",
  },
  imageSubText: {
    fontSize: "2rem",
  },
  paragraph: {
    fontFamily: "Source Sans",
    fontSize: "2rem",
    color: theme.colorText,
    marginBottom: "10px",
  },
  successfulSubmit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "centre",
    paddingBottom: "2em",
  },
  successfulSubmitText: {
    fontFamily: "Bebas",
    fontSize: "6rem",
    color: theme.colorTertiary,
    "@media(max-width:750px)": {
      fontSize: "4rem",
    },
  },
}));

function ContactForm() {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  return (
    <div className={classes.mainContainer}>
      {!formState.isSubmitSuccessful && (
        <motion.div
          className={classes.imageContainer}
          initial={{ translateX: -100, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 100 }}
          viewport={{ once: true }}
        >
          <img
            src="https://images.unsplash.com/photo-1496115965489-21be7e6e59a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            alt="Neon Sign Handshake"
            className={classes.image}
          />
          <div className={classes.imageTextContainer}>
            <h3 className={classes.imageText}>Get in touch with us</h3>
            <p className={classes.imageSubText}>
              We can turn your company's dreams into a reality.
            </p>
          </div>
        </motion.div>
      )}
      <div>
        {!formState.isSubmitSuccessful && (
          <motion.form
            className={classes.form}
            onSubmit={handleSubmit((data) =>
              postData("/api/form/submit", data)
            )}
            initial={{ translateX: 100, opacity: 0 }}
            whileInView={{ translateX: 0, opacity: 100 }}
            viewport={{ once: true }}
          >
            <AboutUsPageHeroHeading
              heading="Looking to elevate your digital presence?"
              subHeading="Let our web development expertise help you succeed. Share your project details below, and we'll promptly get in touch. Let's collaborate and create exceptional results!"
              width100
            />
            <div className={classes.nameCompanyContainer}>
              <div className="inputBox">
                <input autoComplete="name" required {...register("name", { required: true })} />
                <span className={classes.formLabel}>Full Name *</span>
                {errors.name && <p>Full Name is required.</p>}
              </div>
              <div className="inputBox">
                <input
                  // type="email"
                  autoComplete="email"
                  required
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                <span>Email *</span>
                {errors.email && <p>{errors.email.message}</p>}
              </div>
              <div className="inputBox">
                <input autoComplete="organization" required {...register("company")} />
                <span>Company *</span>
              </div>
            </div>
            <div className="inputBox">
              <textarea
                required
                autoComplete="off"
                className={classes.messageContainer}
                {...register("message", { required: true })}
              />
              <span>Send a Message *</span>
              {errors.message && <p>Message is required.</p>}
            </div>
            <Button text="Send Message" onClick={handleSubmit} color />
            {formState.isSubmitSuccessful && formState.isSubmitted && (
              <div className="success">Form submitted successfully</div>
            )}
          </motion.form>
        )}
        {formState.isSubmitSuccessful && formState.isSubmitted && (
          <div className={classes.successfulSubmit}>
            <p className={classes.successfulSubmitText}>
              Thank you for submitting a request to work with us. Our team will
              be in touch with you shortly.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
