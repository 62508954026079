import logo from "./logo.svg";
import "./App.css";
import { createUseStyles, ThemeProvider } from "react-jss";
import HomePage from "./Components/HomePage";
import CustomHeader from "./Components/CustomHeader";
import CustomFooter from "./Components/CustomFooter";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import ServicesPage from "./Components/ServicesPage";
import AboutUsPage from "./Components/AboutUsPage";
import PortfolioPage from "./Components/PortfolioPage";
import NotFoundPage from "./Components/NotFoundPage";
import ContactPage from "./Components/ContactPage";
import TermsAndConditionsPage from "./Components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./Components/PrivacyPolicyPage";
import ScrollToTop from "./Components/ScrollToTop";
import SingleServicePage from "./Components/SingleServicePage";
import CareersPage from "./Components/CareersPage";
import CallToActionSection from "./Components/CallToActionSection";
import MultiStepFormPage from "./Components/MultiStepFormPage";


const theme = {
  colorPrimary: "#89CFF0",
  colorSecondary: "#4A4A4A",
  colorTertiary: "#F3C47E",
  colorText: "#F4ffdf",
  colorTextOpacity80: "rgba(244, 255, 223, 0.80)",
  colorBackground: "#011936",
  colorBackgroundDarker: "#010911",
  colorBackgroundDarkerOpacity50: "rgba(1, 9, 17, 0.5)",
  colorBackgroundDarkerOpacity80: "rgba(1, 9, 17, 0.8)",
  colorBackgroundDarkest: "#000306",
};

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
  }
});

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <div className={classes.mainContainer}>
          <CustomHeader />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/our-work" element={<PortfolioPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/form-example" element={<MultiStepFormPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route exact path="/:listingID/" element={<SingleServicePage />}></Route>
            <Route path="/404" element={<NotFoundPage />}></Route>
            <Route path="*" element={<Navigate to="/404" />}></Route>
          </Routes>
        </div>
        <div className={classes.bottomContainer}>
          <CallToActionSection />
          <CustomFooter />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
