import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import BigPageHeading from "./BigPageHeading";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "./Button";
import SectionHeader from "./SectionHeader";
import lightbulbLightningPic from "../pics/lightbulbwithlightning.png";
import rocketPic from "../pics/rocketfour.webp";
import keyPic from "../pics/KeyBlueRotated.png";
import { motion } from "framer-motion";
import BackgroundText from "./BackgroundText";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";
import servicesData from "../ServicesData";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1750px",
    marginTop: "calc(187px + 2em)",
    gap: "1em",
    color: theme.colorText,
    marginBottom: "2em",
    "@media(max-width:750px)": {
      marginTop: "calc(111px + 2em)",
    }
  },
  subtitle: {
    color: theme.colorTertiary,
    fontSize: "1.5rem",
    // marginBottom: ".25em",
    // background: theme.colorPrimary,
    // padding: ".25em",
  },
  description: {
    fontSize: "2.25rem",
    color: theme.colorText,
  },
  paragraph: {
    fontSize: "2.25rem",
    color: theme.colorText,
    maxWidth: "50%",
    "@media (max-width:750px)": {
      maxWidth: "100%",
    },
  },
}));

function SingleServicePage(props) {
  const classes = useStyles();
  const location = useLocation();
  const currentSlug = location.pathname.slice(1, location.pathname.length);
  const serviceData = servicesData.find(item => item.slug === currentSlug);
  return (
    <Fragment>
      <BackgroundText />
      <div className={classes.mainContainer}>
          <div style={{display: "flex"}}><h4 className={classes.subtitle}>{serviceData.subtitle}</h4></div>
        <BigPageHeading heading={serviceData.title} />
        <motion.div
          initial={{ translateX: -100, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 100 }}
          viewport={{ once: true }}
        >
          <AboutUsPageHeroHeading heading={serviceData.description} width100 />
        </motion.div>
        <SectionHeader header={serviceData.heading1} />
        <motion.div>
          <AboutUsPageHeroHeading heading={serviceData.heading1} subHeading={serviceData.paragraph1} callToAction image={lightbulbLightningPic}/>
        </motion.div>
        <SectionHeader header={serviceData.heading2} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "flex-end",
          }}
        >
          <AboutUsPageHeroHeading heading={serviceData.heading2} subHeading={serviceData.paragraph2} reverse callToAction image={rocketPic} imageMaxHeight={"300px"}/>
        </div>
        <SectionHeader header={serviceData.heading3} />
        <div>
          <AboutUsPageHeroHeading heading={serviceData.heading3} subHeading={serviceData.paragraph3} callToAction image={keyPic}/>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleServicePage;
