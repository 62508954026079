import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    marginBottom: "4em",
    marginTop: "4em",
    justifyContent: "space-between",
    "@media(max-width:750px)": {
      justifyContent: "center",
      alignItems: "center",
    }
  },
  leftContainer: {
    width: "60%",
    "@media(max-width:750px)": {
      width: "100%"
    }
  },
  heading: {
    fontFamily: "Bebas",
    fontSize: "4rem",
    color: theme.colorText,
  },
  paragraph: {
    fontFamily: "Source Sans",
    fontSize: "2rem",
    "@media(max-width:750px)": {
      zIndex: "100"
    }
  },
  image: {
    height: "200px",
    // opacity: "50%",
    "@media(max-width:750px)": {
      position: "absolute",
      height: "220px",
      opacity: "30%",
      zIndex: "-1"
    }
  },
}));

function ServicesPageHeading(props) {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <motion.div
        className={classes.leftContainer}
        initial={{ translateX: -100, opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 100 }}
        transition={{delay: 0.15, duration: 0.5}}
        viewport={{ once: true }}
      >
        <h2 className={classes.heading}>{props.heading}</h2>
        <p className={classes.paragraph}>{props.description}</p>
      </motion.div>
      {props.image ? <motion.img
        className={classes.image}
        src={props.image}
        initial={{ translateY: 100,}}
        whileInView={{ translateY: 0}}
        transition={{delay: 0.15, duration: 0.5}}
        viewport={{ once: true }}
        alt="Heading image"
      ></motion.img> : ""}
    </div>
  );
}

export default ServicesPageHeading;
