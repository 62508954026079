import React, { Fragment, useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import PortfolioItemPreview from "./PortfolioItemPreview";
import SectionHeader from "./SectionHeader";
import houseplantHobbyPic from "../pics/houseplanthobby.webp";
import retroGamePic from "../pics/retrogamerulebook.webp";
import whispersOfAdventurePic from "../pics/whispersofadventurepreview.webp";
import propertizePic from "../pics/propertize5.webp";
import thumbsUpPic from "../pics/customer-feedback.png";
import timingPic from "../pics/timing.png";
import teamPic from "../pics/social-media-marketing.png";
import cubePic from "../pics/brand-awareness.png";
import rocketSVG from "../pics/rocketfour.webp";
import demographicsPic from "../pics/demographics.png";
import { Link } from "react-router-dom";
import FAQSection from "./FAQSection";
import { motion } from "framer-motion";
import { useCallback } from "react";
import HeroSection from "./HeroSection";
import ServicePreview from "./ServicePreview";
import Button from "./Button";
import ContactForm from "./ContactForm";
import ServiceItemPreview from "./ServiceItemPreview";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";
import WhyChooseUsItem from "./WhyChooseUsItem";
import computerWithRocket from "../pics/computer-rocket3.webp";

const useStyles = createUseStyles((theme) => ({
  outerContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainContainer: {
    color: "#F4FFDF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "1750px",
    justifyContent: "center",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
  },
  serviceContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    maxWidth: "1750px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "3em",
    overflow: "hidden",
    "@media (max-width:750px)": {
      flexDirection: "column",
    },
  },
  serviceItemContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    position: "static",
    gap: "1em",
    "@media (max-width:750px)": {
      flexDirection: "column",
    },
  },
  heroVid: {
    // width: "100%",
    height: "calc(100vh - 84.75px)",
    objectFit: "cover",
    opacity: "30%",
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 750px)": {
      height: "100%",
      right: "500px",
    },
    "@media (min-width: 750px)": {
      width: "100%",
    },
  },
  overlay: {
    position: "relative",
    overflow: "hidden",
    // zIndex: "100",
  },
  heroText: {
    maxWidth: "1350px",
    fontSize: "6rem",
    fontFamily: "Bebas",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginBottom: ".15em",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:1250px)": {
      fontSize: "3rem",
    },
    "@media (max-width:750px)": {
      fontSize: "1.75rem",
    },
  },
  heroSubText: {
    fontFamily: "Bebas",
    fontSize: "2rem",
  },
  heroPreText: {
    fontFamily: "Source Sans",
    letterSpacing: "1em",
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: ".35em",
  },
  heroTextContainer: {
    color: "#F4FFDF",
    display: "flex",
    width: "100%",
    height: "100%",
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "3",
  },
  heading: {
    fontSize: "3rem",
  },
  portfolioPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    // alignItems: "center",
    // justifyContent: "space-between",
    gap: "1em",
    marginBottom: "2em",
    // marginTop: "2em",
    maxWidth: "1750px",
    "@media(max-width:750px)": {
      // gap: "4em",
      // marginBottom: "6em",
    },
  },
  serviceIcon: {
    width: "100px",
    // height: "100px",
    // objectFit: "cover",
  },
  arrowIcon: {
    fontSize: "2rem",
    filter: "invert(100%)",
  },
  orange: {
    // color: "#FFD300",
    // color: "#E2D1F9",
    color: theme.colorPrimary,
  },
  magenta: {
    // color: "#317773",
    color: theme.colorSecondary,
    textDecoration: "underline",
  },
  whyChooseUsHeading: {
    fontFamily: "Bebas",
    color: theme.colorPrimary,
    fontSize: "1.5rem",
  },
  whyChooseUsText: {
    // width: "500px",
    fontSize: "1.35rem",
    marginBottom: "1em",
    "@media (max-width:750px)": {},
  },
  whyChooseUsContainer: {
    display: "flex",
    // color: "#F4FFDF",
    gap: "2em",
    maxWidth: "1750px",
    fontFamily: "Source Sans",
    // marginBottom: "1em",
    "@media (max-width: 750px)": {
      flexDirection: "column",
      marginBottom: "0px",
    },
  },
  whyChooseUsInnerLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2em",
    // marginBottom: "5em",
    width: "50%",
    "@media (max-width: 750px)": {
      width: "100%",
      gap: "0em",
    },
  },
  whyChooseUsLeftBottomContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2em",
    "@media(max-width:750px)": {
      flexDirection: "column-reverse",
      marginTop: "2em",
    },
  },
  whyChooseUsLeftBottomInnerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  whyChooseUsLeftBottomInnerContainerHeading: {
    fontFamily: "Bebas",
    fontSize: "1.75rem",
    color: theme.colorPrimary,
  },
  whyChooseUsImage: {
    objectFit: "contain",
    width: "50%",
    "@media (max-width: 750px)": {
      width: "100%",
    },
  },
  whyChooseUsInnerRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "50%",
    "@media (max-width: 750px)": {
      width: "100%",
      // alignItems: "flex-start",
    },
  },
  whyChooseUsList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: "1em",
  },
  whyChooseUsLeftHeading: {
    fontFamily: "Bebas",
    fontSize: "7rem",
    "@media (max-width:750px)": {
      fontSize: "3.5rem",
    },
  },
  whyChooseUsLeftParagraph: {
    // color: "#f4ffdf",
    fontSize: "1.75rem",
  },
  currentWhyChooseUsWord: {
    color: theme.colorPrimary,
  },
  centeredSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centeredSectionTwo: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    width: "100%",
  },
  paragraph: {
    fontSize: "2rem",
    fontFamily: "Source Sans",
  },
}));

function HomePage() {
  const classes = useStyles();
  const currentWordRef = useRef("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = [
    "Innovative",
    "Bold",
    "Stunning",
    "Adventurous",
    "Revolutionary",
    "Modern",
    "Original",
    "State-of-the-Art",
    "Contemporary",
    "Cutting-edge",
    "Dynamic",
    "Sophisticated",
    "Ground-breaking",
    "Striking",
    "Exceptional",
    "Pristine",
    "Inspiring",
  ];
  const designItems = [
    ["Website Design", "website-design"],
    ["UI/UX", "website-design"],
    ["Content Design", "branding"],
    ["Branding", "branding"],
  ];
  const developmentItems = [
    ["Websites", "custom-web-development"],
    ["Internal Tools", "internal-tools"],
    ["Full Stack Applications", "custom-web-development"],
    ["API Development", "backend-development"],
    ["Custom CMS", "backend-development"],
    ["React", "custom-web-development"],
    ["React Native", "mobile-development"],
    ["Mobile", "mobile-development"],
    ["Wordpress", "custom-web-development"],
  ];
  const deliveryItems = [
    ["Hosting", "hosting-and-maintenance"],
    ["Maintenance", "hosting-and-maintenance"],
    ["White Labeling", "white-labeling"],
    ["Backups", "hosting-and-maintanence"],
    ["Docker", "deployment-options"],
  ];
  const [currentWord, setCurrentWord] = useState([words[0]]);

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * words.length);
    setCurrentWord(words[index]);
  }, []);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <Fragment>
      <HeroSection />
      <div className={classes.outerContainer}>
        <SectionHeader header="What We Offer" />
        <div className={classes.mainContainer}>
          <motion.div
            transition={{
              default: { ease: "linear" },
            }}
            className={classes.centeredSection}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <div className={classes.serviceContainer}>
              <ServicePreview number="01" title="Design" items={designItems} />
              <ServicePreview
                number="02"
                title="Development"
                items={developmentItems}
              />
              <ServicePreview
                number="03"
                title="Delivery"
                items={deliveryItems}
              />
            </div>
            <Link to="/services">
              <Button text="Learn More" />
            </Link>
          </motion.div>
          <motion.div
            transition={{
              x: { duration: 1 },
              default: { ease: "linear" },
            }}
            className={classes.centeredSection}
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
          >
            <SectionHeader header="Our Work" />
            <AboutUsPageHeroHeading
              heading="Empower Your Business with Our Expertise"
              subHeading="Want to know how our expertise can make a difference in your business? Check out a few projects that we're really proud of. They represent our commitment to delivering top-quality and creative solutions that drive genuine, meaningful success."
              image={rocketSVG}
            />
            <motion.ul
              variants={container}
              initial="hidden"
              animate="show"
              className={classes.portfolioPreviewContainer}
            >
              <PortfolioItemPreview
                description="Venture into the realm of Whispers of Adventure, a mesmerizing online platform dedicated to sharing riveting short stories. Our skilled team designed and implemented a responsive React front end, and developed a fast, scalable NodeJS backend paired with a well-structured PostgreSQL database that we continue to maintain. We also designed and implemented automated processes in the backend to streamline business operations."
                projectName="Whispers of Adventure"
                technologies={["React", "PSQL", "NodeJS"]}
                itemNumber="1"
                image={whispersOfAdventurePic}
                link="https://whispersofadventure.com"
                left
              />
              <PortfolioItemPreview
                description="Our team's proficiency in WordPress enabled us to create an engaging platform for plant enthusiasts with Houseplant Hobby. The striking design, seamless user experience, and diverse array of resources showcase our passion for delivering top-notch digital experiences that cater to users with varying levels of expertise in the houseplant hobby."
                projectName="Houseplant Hobby"
                technologies={["Wordpress"]}
                itemNumber="3"
                image={houseplantHobbyPic}
                link="https://houseplanthobby.com"
                right
              />
              <PortfolioItemPreview
                description="Retro Game Rulebook was created while keeping the heart of retro gamer's in mind. Our team's proficiency in WordPress development enabled us to create an engaging online platform for retro gaming enthusiasts. The responsive design, seamless user experience, and rich content showcase our commitment to excellence in web development, rekindling your love for classic gaming."
                projectName="Retro Game Rulebook"
                technologies={["Wordpress"]}
                itemNumber="4"
                image={retroGamePic}
                link="https://retrogamerulebook.com"
                left
              />
              {/* <PortfolioItemPreview
                description="Propertize is a SaaS we built to empower real estate agents. Leveraging the power of NextJS and serverless architecture, scaling to millions of users is no problem. Propertize is a one-stop solution designed for forward-thinking real estate agents. A tool that will revolutionize your business from lead generation to closing deals. Entering beta soon."
                projectName="Propertize"
                technologies={["NextJS", "Serverless"]}
                itemNumber="4"
                image={propertizePic}
                link="https://propertize.pro"
                right
              /> */}
            </motion.ul>
            <Link to="/our-work">
              <Button text="View all of our work" />
            </Link>
          </motion.div>
          <motion.div
            transition={{ duration: 0.5 }}
            className={classes.centeredSectionTwo}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <SectionHeader header="Why Choose Studio White Tiger?" />
            <div className={classes.whyChooseUsContainer}>
              <motion.div
                transition={{ delay: 0.25 }}
                className={classes.whyChooseUsInnerLeftContainer}
                initial={{ opacity: 0, translateX: -100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ once: true }}
              >
                <h2 className={classes.whyChooseUsLeftHeading}>
                  <span className={classes.currentWhyChooseUsWord}>
                    Unleash Business Growth
                  </span>
                  <br /> with Customized Solutions
                </h2>
                <div className={classes.whyChooseUsLeftBottomContainer}>
                  <img
                    className={classes.whyChooseUsImage}
                    src={computerWithRocket}
                    alt="computer with rocket"
                  />
                  <div className={classes.whyChooseUsLeftBottomInnerContainer}>
                    <h3 className={classes.whyChooseUsLeftBottomInnerContainerHeading}>Studio White Tiger</h3>
                    <p className={classes.whyChooseUsLeftParagraph}>
                      Your partner in building remarkable digital experiences. We
                      connect with your business goals, translate them into
                      innovative web solutions, and help you stand out online.
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                transition={{ delay: 0.25 }}
                className={classes.whyChooseUsInnerRightContainer}
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ once: true }}
              >
                {" "}
                <ul className={classes.whyChooseUsList}>
                  <WhyChooseUsItem
                    heading="Experienced Team"
                    description="Benefit from our team's expertise in delivering top-tier websites and apps on time, every time."
                  />
                  <WhyChooseUsItem
                    heading="Custom Solutions"
                    description="We craft solutions unique to your needs because we understand every client is different."
                  />
                  <WhyChooseUsItem
                    heading="All-in-One Services"
                    description="We handle everything from design to hosting, letting you focus solely on your vision."
                  />
                  <WhyChooseUsItem
                    heading="Dedicated Support"
                    description="Exceptional support when you need it – we're always committed to helping you."
                  />
                  <WhyChooseUsItem
                    heading="Competitive Pricing"
                    description="Quality web solutions at prices that give you the best value for your investment."
                  />
                  <WhyChooseUsItem
                    heading="Latest Technologies"
                    description="Stay ahead with a website built using the most advanced tools and techniques."
                  />
                  <WhyChooseUsItem
                    heading="Proven Success"
                    description="Our portfolio of successful projects ensures you're partnering with a team that delivers."
                  />
                  <WhyChooseUsItem
                    heading="User-Friendly Design"
                    description="Engage your audience easily with our accessible and intuitive web designs"
                  />
                  <WhyChooseUsItem
                    heading="White Labeling"
                    description="Your brand, front and center, giving you total control over your online presence."
                  />
                </ul>
              </motion.div>
            </div>
            <SectionHeader header="Innovative Web Design and Development to Elevate Your Business." />
          </motion.div>
          <motion.div
            className={classes.serviceItemContainer}
            initial={{ opacity: 0, translateX: -100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ once: true }}
          >
            <ServiceItemPreview
              title="Quality Work"
              text="We prioritize excellence in design and functionality, delivering high-quality solutions to help you stand out and succeed."
              delay="1"
              image={thumbsUpPic}
              once
            />
            <ServiceItemPreview
              title="On-Time Delivery"
              text="Timely & Reliable: We respect deadlines and consistently complete projects on time, ensuring a smooth and efficient process for achieving your goals."
              delay="2"
              image={timingPic}
              once
            />
            <ServiceItemPreview
              title="Friendly and Experienced Team"
              text="Expert Partners: Our approachable, skilled team collaborates with you, crafting solutions that surpass expectations."
              image={teamPic}
              delay="3"
              once
            />
            <ServiceItemPreview
              title="White Label Solutions"
              text="Expand Your Services: Our white label solutions let you broaden your offerings while we manage the technical and creative work, empowering your business growth."
              delay="4"
              image={cubePic}
              once
            />
            <ServiceItemPreview
              title="Solutions of Any Size"
              text="Adaptable Expertise: Catering to businesses of all sizes, we customize our solutions to fit your unique requirements and enable seamless, scalable success."
              delay="5"
              image={demographicsPic}
              once
            />
          </motion.div>
          <FAQSection />
          <SectionHeader header="Get in Touch" />
          <ContactForm />
        </div>
      </div>
    </Fragment>
  );
}

export default HomePage;
