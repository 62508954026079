import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import logo from "../tigerlogowhite.svg";
import { motion } from "framer-motion";
import instagramLogo from "../pics/instagramlogo.svg"
import youtubeLogo from "../pics/youtubelogo.svg"
import twitterLogo from "../pics/twitterlogo.svg"
import facebookLogo from "../pics/facebooklogo.svg"
import Button from "./Button";


const useStyles = createUseStyles((theme) => ({
  outerContainer: {
    display: "flex",
    background: "transparent",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    // maxWidth: "1750px",
    display: "flex",
    flexDirection: "column",
    minHeight: "400px",
    height: "100%",
    alignItems: "center",
    color: theme.colorText,
    justifyContent: "center",
    width: "100%",
    // textAlign: "center",
    "@media (max-width: 750px)": {
      // height: "600px",
    },
  },
  topContainer: {
    display: "flex",
    fontFamily: "Bebas",
    paddingTop: "2em",
    paddingBottom: "2em",
    background: "#000306",
    height: "30%",
    width: "100%",
    // paddingLeft: "1em",
    // paddingTop: "1em",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 750px)": {
    },
  },
  topContainerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1750px",
    "@media (max-width: 750px)": {
      flexDirection: "column"
      
    },
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "center",
    height: "70%",
    background: "#010911",
    paddingTop: "2em",
    paddingBottom: "2em",
    width: "100%",
    "@media (max-width: 750px)": {
      // height: "45%",
    },
  },
  bottomContainerContent: {
    display: "flex",
    width: "100%",
    maxWidth: "1750px",
    justifyContent: "space-between",
    "@media (max-width: 750px)": {
      flexDirection: "column",
      
    },
    
  },
  topContainerInnerLeft: {
    display: "flex",
    gap: "1em",
    // fontSize: "3rem",
    width: "70%",
    marginLeft: "1em",
    "@media (max-width: 750px)": {
      width: "100%",
      margin: "0",
      paddingLeft: "1em",
    },
  },
  topContainerInnerRight: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Bebas",
    marginLeft: "1em",
    marginRight: "1em",
    // fontSize: "1.5rem",
    width: "30%",
    "@media (max-width: 750px)": {
      width: "100%",
      padding: "1em",
    },
    
  },
  heading: {
    fontSize: "3rem",
    color: theme.colorPrimary,
    fontFamily: "Bebas",
    fontWeight: "900",
    "@media (max-width: 750px)": {
      fontSize: "2rem",
    },
    
  },
  subHeading: {
    fontSize: "3rem",
    fontFamily: "Bebas",
    fontWeight: "100",
    "@media (max-width: 750px)": {
      fontSize: "2rem",
    },
  },
  emailLink: {
    alignSelf: "flex-start",
    cursor: "pointer",
    fontSize: "2.75rem",
    fontFamily: "Bebas",
    fontWeight: "100",
    transition: "border .25s ease-in-out",
    borderBottom: "4px solid transparent",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
    "@media(max-width: 750px)": {
      fontSize: "2rem",
    }
  },
  phoneNumberLink: {  
    alignSelf: "flex-start",
    cursor: "pointer",
    transition: "border .25s ease-in-out",
    borderBottom: "4px solid transparent",
    fontSize: "2.75rem",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
  },
  bottomLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    marginLeft: "1em",
    "@media (max-width: 750px)": {
      width: "100%",
      marginLeft: "0",
      padding: "1em",
    },
  },
  bottomRightContainer: {
    width: "30%",
    marginLeft: "1em",
    marginRight: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "flex-end",
    // justifyContent: "center",
    "@media (max-width: 750px)": {
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: "1em",
      margin: "0",
      gap: ".5em",
    },
  },
  footerBottomRightLink: {
    display: "flex",
    fontFamily: "Bebas",
    fontSize: "1.5rem",
    cursor: "pointer",
    marginTop: ".25em",
    borderBottom: "4px solid transparent",
    transition: "border .25s ease-in-out",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
    "@media (max-width: 750px)": {
      // gap: "2em",
    },
  },
  footerLeftExcerpt: {
    fontFamily: "Bebas",
    fontSize: "1.35rem",
  },
  socialMediaContainer: {
    marginTop: "1em",
    display: "flex",
  },
  socialMediaButton: {
    fontFamily: "Bebas",
    fontSize: "2rem",
    height: "35px",
    cursor: "pointer",
    marginLeft: "1em",
    borderBottom: "4px solid transparent",
    transition: "all .5s ease-in-out",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
      transform: "rotate(360deg)"
    },
  },
  getInTouchLink: {
    color: theme.colorPrimary,
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: "8px",
    transition: "color .25s ease-in-out",
    "&:hover": {
      color: theme.colorSecondary,
    },
    // borderBottom: "3px solid white",
  },
  logo: {
    height: "75px",
    cursor: "pointer",
  },
  linkUnderline: {
    borderBottom: "4px solid transparent",
    transition: "border .25s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
  },
}));

function CustomFooter() {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.outerContainer}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: .5 }}
      // viewport={{ once: true }}
    >
      <div className={classes.mainContainer}>
        <div className={classes.topContainer}>
          <div className={classes.topContainerContent}>
            <div className={classes.topContainerInnerLeft}>
              <Link to="/">
                <img className={classes.logo} src={logo} alt="Studio White Tiger logo" />
              </Link>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className={classes.heading}>Enjoy what you see?</p>
                <p className={classes.subHeading}>
                  Let's get in{" "}
                  <Link to="/contact">
                    <span className={classes.getInTouchLink}>touch</span>
                  </Link>
                </p>
              </div>
            </div>
            <div className={classes.topContainerInnerRight}>
              <p>
                We collaborate with innovative brands to create cutting-edge
                digital experiences.
              </p>
              <p>Get a free quote today</p>
              <Link to="/contact" style={{ display: "flex" }}>
                <p className={classes.linkUnderline}>Let's Connect</p>
              </Link>
              <p>Mon - Fri | 10AM - 6PM EST</p>
              <a href="tel:1-484-750-1905" className={classes.phoneNumberLink}>1 (484) 750 1905</a>
              <a
                href="mailto:info@studiowhitetiger.com"
                className={classes.emailLink}
              >
                info@studiowhitetiger.com
              </a>
            </div>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.bottomContainerContent}>
            <div className={classes.bottomLeftContainer}>
              <p className={classes.footerLeftExcerpt}>
                Unleash Your Web Potential with Studio White Tiger. Our team of
                experienced developers is dedicated to delivering top-notch web
                design and development services, customized to meet your
                specific needs. From fully custom Wordpress sites to
                high-performance React web apps, we've got you covered. And with
                our white labeling options and unparalleled support, you can be
                confident that your website is in good hands. Discover the power
                of a truly remarkable web presence with us.
              </p>
              <div className={classes.socialMediaContainer}>
                {/* <div className={classes.socialMediaButton}>Facebook</div>
                <div className={classes.socialMediaButton}>Twitter</div>
                <div className={classes.socialMediaButton}>LinkedIn</div>
                <div className={classes.socialMediaButton}>Instagram</div> */}
                <a href=""><img className={classes.socialMediaButton} src={facebookLogo} /></a>
                <a href="https://twitter.com/StudioWTiger"><img className={classes.socialMediaButton} src={twitterLogo} /></a>
                <a href="https://www.youtube.com/@studiowhitetiger"><img className={classes.socialMediaButton} src={youtubeLogo} /></a>
                <a href=""><img className={classes.socialMediaButton} src={instagramLogo} /></a>
              </div>
            </div>
            <div className={classes.bottomRightContainer}>
              <Link className="" to="/services">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>Services</p>
                </div>
              </Link>
              <Link className="" to="/contact">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>Contact</p>
                </div>
              </Link>
              <Link className="" to="/about-us">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>About us</p>
                </div>
              </Link>
              <Link className="" to="/contact">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>
                    Get a Free Quote
                  </p>
                </div>
              </Link>
              <Link className="" to="/our-work">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>
                    View Examples of Our Work
                  </p>
                </div>
              </Link>
              <Link className="" to="/privacy-policy">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>
                    Privacy Policy
                  </p>
                </div>
              </Link>
              <Link className="" to="/terms-and-conditions">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>
                    Terms and Conditions
                  </p>
                </div>
              </Link>
              <Link className="" to="/careers">
                <div style={{ display: "flex" }}>
                  <p className={classes.footerBottomRightLink}>
                    Careers
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CustomFooter;
