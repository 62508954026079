import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import SectionHeader from "./SectionHeader";
import { motion } from "framer-motion";
import PortfolioItemPreview from "./PortfolioItemPreview";
import houseplantHobbyPic from "../pics/houseplanthobby.webp";
import retroGamePic from "../pics/retrogamerulebook.webp";
import propertizePic from "../pics/propertize5.webp";
import whispersOfAdventurePic from "../pics/whispersofadventurepreview.webp";
import handPic from "../pics/handcolor.png";
import envelopePic from "../pics/envelope.png";
import BigPageHeading from "./BigPageHeading";
import BackgroundText from "./BackgroundText";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    marginTop: "calc(187px + 2em)",
    marginBottom: "2em",
    flexDirection: "column",
    alignItems: "center",
    color: theme.colorText,
    overflow: "hidden",
    width: "100%",
    maxWidth: "1750px",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
    },
  },
  title: {
    fontFamily: "Bebas",
    color: "white",
    fontSize: "3rem",
  },
  titleSecondHalf: {
    color: "#D3C247",
    textDecoration: "underline",
  },
  portfolioPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    marginTop: "1em",
    marginBottom: "1em",
  },
  paragraph: {
    fontFamily: "Source Sans",
    fontSize: "2rem",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2em",
  },
}));

function PortfolioPage() {
  const classes = useStyles();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  return (
    <Fragment>
      <BackgroundText />
      <div className={classes.mainContainer}>
        {/* <h1 className={classes.title}>Our <span className={classes.titleSecondHalf}>Work</span></h1> */}
        <BigPageHeading heading="The Art of Impactful Web Design: A Glimpse into Our Work at Studio White Tiger" />
        <AboutUsPageHeroHeading
          heading="Results-Driven Websites, Delivered"
          subHeading="At Studio White Tiger, we are proud of the work we have delivered for our clients. Our portfolio below showcases a selection of our recent projects and highlights our expertise in web application development and design."
          callToAction
          image={handPic}
        />
        <SectionHeader header="Our Work" />
        <motion.ul
          variants={container}
          initial="hidden"
          animate="show"
          className={classes.portfolioPreviewContainer}
        >
          <PortfolioItemPreview
            description="Our team's proficiency in WordPress enabled us to create an engaging platform for plant enthusiasts with Houseplant Hobby. The striking design, seamless user experience, and diverse array of resources showcase our passion for delivering top-notch digital experiences that cater to users with varying levels of expertise in the houseplant hobby."
            projectName="Houseplant Hobby"
            technologies={["Wordpress"]}
            itemNumber="1"
            image={houseplantHobbyPic}
            link="https://houseplanthobby.com"
            left
          />
          <PortfolioItemPreview
            description="Retro Game Rulebook was created while keeping the heart of retro gamer's in mind. Our team's proficiency in WordPress development enabled us to create an engaging online platform for retro gaming enthusiasts. The responsive design, seamless user experience, and rich content showcase our commitment to excellence in web development, rekindling your love for classic gaming."
            projectName="Retro Game Rulebook"
            technologies={["Wordpress"]}
            itemNumber="2"
            image={retroGamePic}
            link="https://retrogamerulebook.com"
            right
          />
          <PortfolioItemPreview
            description="Venture into the realm of Whispers of Adventure, a mesmerizing online platform dedicated to sharing riveting short stories. Our skilled team designed and implemented a responsive React front end, and developed a fast, scalable NodeJS backend paired with a well-structured PostgreSQL database that we continue to maintain. We also designed and implemented automated processes in the backend to streamline business operations."
            technologies={["React", "PSQL", "NodeJS"]}
            projectName="Whispers of Adventure"
            itemNumber="3"
            image={whispersOfAdventurePic}
            link="https://whispersofadventure.com"
            left
          />
          {/* <PortfolioItemPreview
            description="Propertize is a SaaS we built to empower real estate agents. Leveraging the power of NextJS and serverless architecture, scaling to millions of users is no problem. Propertize is a one-stop solution designed for forward-thinking real estate agents. A tool that will revolutionize your business from lead generation to closing deals. Entering beta soon."
            projectName="Propertize"
            technologies={["NextJS", "Serverless"]}
            itemNumber="4"
            image={propertizePic}
            link="https://propertize.pro"
            right
          /> */}
        </motion.ul>
        <SectionHeader header="Elevate Your Brand" />
        <AboutUsPageHeroHeading
          heading="Inspired by Our Work? Let's Create Yours"
          subHeading="Connect with our expert team and embark on the journey to a powerful, successful online presence."
          callToAction
          buttonText="Let's Talk"
          // width100
          image={envelopePic}
          imageMaxHeight="250px"
        />
      </div>
    </Fragment>
  );
}

export default PortfolioPage;
