import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    background: "transparent !important",
    "&:after": {
      background: theme.colorPrimary,
    }
  },
}));

function Button(props) {
  const classes = useStyles();
  return (
    <div>
      <button type={props.button ? "button" : ""} style={{}} className={props.color ? "button-52" : `button-52 ${classes.mainContainer}`}>{props.text}</button>
    </div>
  );
}

export default Button;
