import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { useForm } from "react-hook-form";
import SectionHeader from "./SectionHeader";
import BackgroundText from "./BackgroundText";
import Button from "./Button";
import BigPageHeading from "./BigPageHeading";
import cloudPic from "../pics/cloud-cropped.png";
import { motion } from "framer-motion";
import instagramLogo from "../pics/instagramlogo.svg"
import youtubeLogo from "../pics/youtubelogo.svg"
import twitterLogo from "../pics/twitterlogo.svg"
import facebookLogo from "../pics/facebooklogo.svg"
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: theme.colorText,
    marginTop: "calc(187px + 2em)",
    maxWidth: "1750px",
    width: "100%",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1em",
    marginBottom: "2em",
  },
  halfWidth: {
    width: "100%",
    height: "50px",
    position: "relative",
    background: theme.colorBackground,
    marginBottom: "2em",
    "& input": {
      fontSize: "1.5rem",
      color: theme.colorText,
      border: `1px solid ${theme.colorText}`,
      // boxSizing: "border-box",
      width: "100%",
      height: "50px",
      background: theme.colorBackground,
      // width: "100%",
      padding: "0 1em",
      "&:focus, &:hover": {
        border: `1px solid ${theme.colorPrimary}`,
        outline: "none",
        // fontSize: "2rem",
      },
    },
    "& span": {
      position: "absolute",
      textTransform: "uppercase",
      padding: "1.2em",
      pointerEvents: "none",
      color: theme.colorSecondary,
      left: "0",
      top: "0",
    },
    "&:hover $formLabel": {
      fontSize: "4rem",
    },
    // marginRight: "4em"
  },
  marginRight: {
    // marginRight: "3em",
  },
  fullWidth: {
    // width: "100%",
    height: "50px",
    marginBottom: "2em",
    background: theme.colorBackground,
  },
  nameCompanyContainer: {
    display: "flex",
    gap: "1em",
    width: "100%",
    justifyContent: "space-between",
    "@media (max-width:750px)": {
      flexDirection: "column",
    },
  },
  heroContainer: {
    display: "flex",
    width: "100%",
    gap: "1em",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2em",
    "@media (max-width:750px)": {
      flexDirection: "column",
    },
  },
  heroLeftInnerLeft: {
    width: "100%",
    fontFamily: "Bebas",
    fontSize: "clamp(2rem, 10px, 5rem)",
  },
  heroLeftContainer: {
    display: "flex",
    width: "100%",
    alignItems: "space-between",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
  heroLeftInnerRight: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    maxHeight: "200px",
    objectFit: "contain",
    paddingRight: "1em",
  },
  heroRightContainer: {
    width: "100%",
    display: "flex",
    fontFamily: "Source Sans",
    fontSize: "2rem",
    alignItems: "center",
    maxWidth: "50%",
    "@media (max-width:750px)": {
      maxWidth: "100%",
    },
  },
  messageContainer: {
    minHeight: "400px",
    background: "transparent",
    // marginBottom: "2em",
  },
  fullNameContainer: {
    // position: "relative",
    width: "100%",
    height: "50px",
    background: theme.colorBackground,
  },
  emailLinkText: {
    cursor: "pointer",
    transition: "border .25s ease-in-out",
    paddingBottom: ".1em",
    borderBottom: "4px solid transparent",
    "&:hover": {
      // color: theme.colorPrimary,
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
    "&:hover $emailLinkText": {},
  },
  socialMediaButton: {
    fontFamily: "Bebas",
    fontSize: "2rem",
    maxHeight: "45px",
    cursor: "pointer",
    marginLeft: ".25em",
    borderBottom: "4px solid transparent",
    transition: "all .5s ease-in-out",
  },
  socialMediaLink: {
    display: "flex",
    cursor: "pointer",
    "&:hover $socialMediaButton": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
      transform: "rotate(360deg)",
    },
    "&:hover $socialMediaTitle": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
  },
  socialMediaTitle: {
    transition: "all .5s ease-in-out",
    borderBottom: `4px solid transparent`,
  },
  successfulSubmit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "centre",
    minHeight: "calc(100vh - calc(111px + 1em))",
    paddingBottom: "5em",
  },
  successfulSubmitText: {
    fontFamily: "Bebas",
    fontSize: "6rem",
    color: theme.colorTertiary,
    "@media(max-width:750px)": {
      fontSize: "4rem",
    },
  },
}));

function ContactPage() {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  // const handleThoughtSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = {
  //     body: e.target.body.value,
  //   };
  //   postData("localhost:5001/api/form/submit", formData);
  // };

  return (
    <Fragment>
      <BackgroundText />
      <motion.div
        className={classes.mainContainer}
        initial={{ translateX: -100, opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 100 }}
        viewport={{ once: true }}
      >
        {!formState.isSubmitSuccessful && (
          <Fragment>
            <BigPageHeading heading="Reach Out: Let's Collaborate to Transform Your Online Presence" />
            <SectionHeader header="Contact Us" />
            <div className={classes.heroContainer}>
              <div className={classes.heroLeftContainer}>
                <motion.div
                  className={classes.heroLeftInnerLeft}
                  initial={{ translateX: -100, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 100 }}
                  transition={{ delay: 0.25 }}
                  viewport={{ once: true }}
                >
                <p>Mon - Fri | 10AM - 6PM EST</p>
                <div style={{display: "flex"}}>
                  <a className={classes.emailLinkText} href="tel:1-484-750-1905">1 (484) 750 1905 </a>
                </div>
                  <div style={{ display: "flex" }}>
                    <a
                      className={classes.emailLink}
                      href="mailto:info@studiowhitetiger.com"
                    >
                      <h2 className={classes.emailLinkText}>
                        info@studiowhitetiger.com
                      </h2>
                    </a>
                  </div>
                  <div className={classes.socialMediaLink}>
                    <h2 className={classes.socialMediaTitle}>Facebook</h2>
                    <img className={classes.socialMediaButton} src={facebookLogo} />
                  </div>
                  <a href="https://twitter.com/StudioWTiger">
                    <div className={classes.socialMediaLink}>
                      <h2 className={classes.socialMediaTitle}>Twitter</h2>
                      <img className={classes.socialMediaButton} src={twitterLogo} />
                    </div>
                  </a>
                  <a href="https://youtube.com/@studiowhitetiger">
                    <div className={classes.socialMediaLink}>
                      <h2 className={classes.socialMediaTitle}>YouTube</h2>
                      <img className={classes.socialMediaButton} src={youtubeLogo} />
                    </div>
                  </a>
                  <div className={classes.socialMediaLink}>
                    <h2 className={classes.socialMediaTitle}>Instagram</h2>
                    <img className={classes.socialMediaButton} src={instagramLogo} />
                  </div>
                </motion.div>
                <motion.div
                  className={classes.heroLeftInnerRight}
                  initial={{ translateX: -100, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 100 }}
                  transition={{ delay: 0.5 }}
                  viewport={{ once: true }}
                >
                  <img className={classes.image} src={cloudPic} alt="Digital Cloud"/>
                </motion.div>
              </div>
              <div className={classes.heroRightContainer}>
                <AboutUsPageHeroHeading
                  heading="Ready to Take Your Online Presence to New Heights? Contact us and get a free quote today!"
                  subHeading="Our team is open for your ideas, questions and needs. Our clients
              get superior results when a short-term acquaintance turns into a
              long-term collaboration."
                  width100
                />
              </div>
            </div>
          </Fragment>
        )}

        {!formState.isSubmitSuccessful && (
          <form
            className={classes.form}
            onSubmit={handleSubmit((data) =>
              postData("/api/form/submit", data)
            )}
            // onSubmit={handleThoughtSubmit}
          >
            <div className={classes.nameCompanyContainer}>
              <div className="inputBox">
                <input autoComplete="name" required {...register("name", { required: true })} />
                <span className={classes.formLabel}>Full Name *</span>
                {errors.name && <p>Full Name is required.</p>}
              </div>
              <div className="inputBox">
                <input
                  autoComplete="email"
                  // type="email"
                  required
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                <span>Email *</span>
                {errors.email && <p>{errors.email.message}</p>}
              </div>
              <div className="inputBox">
                <input autoComplete="organization" required {...register("company")} />
                <span>Company *</span>
              </div>
            </div>
            <div className="inputBox">
              <textarea
                required
                autoComplete="off"
                className={classes.messageContainer}
                {...register("message", { required: true })}
              />
              <span>Send a Message *</span>
              {errors.message && <p>Message is required.</p>}
            </div>
            <Button text="Send Message" onClick={handleSubmit} color />
            {formState.isSubmitSuccessful && formState.isSubmitted && (
              <div className="success">Form submitted successfully</div>
            )}
          </form>
        )}
        {formState.isSubmitSuccessful && formState.isSubmitted && (
          <div className={classes.successfulSubmit}>
            <p className={classes.successfulSubmitText}>
              Thank you for submitting a request to work with us. Our team will
              be in touch with you shortly.
            </p>
          </div>
        )}
      </motion.div>
    </Fragment>
  );
}

export default ContactPage;
