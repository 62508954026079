import React from "react";
import { createUseStyles } from "react-jss";
import FAQItem from "./FAQItem";
import SectionHeader from "./SectionHeader";
import { motion } from "framer-motion";
import questionPic from "../pics/QuestionBlue2.webp";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";


const useStyles = createUseStyles((theme) =>({
  mainContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    transition: "all 0.5s ease-in-out",
    // alignItems: "center",
    
  },
  collapse: {
    marginBottom: "2em",
  },
  
}));

function FAQSection() {
  const classes = useStyles()
  return(
    <motion.div className={classes.mainContainer} initial={{ opacity: 0, translateX: -100 }}
        whileInView={{ opacity: 1, translateX: 0 }} viewport={{once: true}}>
      <SectionHeader header="Frequently Asked Questions" />
      <AboutUsPageHeroHeading heading="Your Questions Answered, Your Success Unlocked" subHeading="Dive into our FAQ section to learn how our expertise and services can propel your business forward." width100 />
      <ul>
      <div className={classes.collapse}>
        <FAQItem delay="1" question="What services does Studio White Tiger provide?" answer="We offer an array of services like custom web application development (including full-stack React/Node/PSQL and WordPress), static landing pages, hosting, maintenance, and white labeling." />
        <FAQItem delay="2" question="What's your process for a new project?" answer="Every project is unique, and we start by understanding your specific goals. We collaborate with you to design a solution that not only meets but exceeds your expectations." />
        <FAQItem delay="3" question="Will I be involved in the development process?" answer="Definitely. We believe in transparency and include you at every stage, providing regular updates so you're always in the know about your project's progress." />
        <FAQItem delay="4" question="Can you update or repair my existing website?" answer="Absolutely. Each project is unique, so reach out to us and we can discuss the best options for your site." />
        <FAQItem delay="5" question="Do you collaborate with existing teams or offer white labeling services?" answer="Yes, we're extremely flexible. We can work seamlessly with your existing team and also offer white labeling services, allowing you to use our expertise behind the scenes." />
        <FAQItem delay="6" question="How can we get a quote?" answer="You can reach out to us directly on our contact page or by using the contact form below, and we will get in touch with you for a more personalized quote." />
        {/* <FAQItem delay="7" question="Do you offer white labeling services?" answer="Yes, we offer white labeling services to clients who want to use our expertise and development services to deliver solutions to their own clients. Our team will work behind the scenes to deliver high-quality software solutions that meet the client's specific needs." /> */}
        <FAQItem delay="8" question="Can you do mobile development?" answer="Yes, we're proficient in mobile development as well, delivering for both iOS and Android by using React Native." />
        <FAQItem delay="9" question="What's the typical project turnaround time?" answer="Project timelines vary based on requirements. We align our timeline with yours, delivering quality solutions on time and within budget." />
      </div>
      {/* <p className={classes.paragraph} >If you have any other questions, please don't hesitate to contact us. Our team is here to help and to ensure that you receive the support and guidance you need.</p> */}
      <AboutUsPageHeroHeading heading="If you have any other questions, please don't hesitate to contact us." subHeading=" Our team is here to help and to ensure that you receive the support and guidance you need." image={questionPic} callToAction />
      </ul>
    </motion.div>
  );
}

export default FAQSection;