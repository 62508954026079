import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { set, useForm } from "react-hook-form";
import SectionHeader from "./SectionHeader";
import BackgroundText from "./BackgroundText";
import Button from "./Button";
import BigPageHeading from "./BigPageHeading";
import { motion } from "framer-motion";
import gearPic from "../pics/gearblue.png";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";
import { Link } from "react-router-dom";
import { string, object } from "yup";
import rocketPic from "../pics/rocketfour.webp";
import laptopPic from "../975.svg"
import {yupResolver} from "@hookform/resolvers/yup";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: theme.colorText,
    marginTop: "calc(187px + 2em)",
    padding: "2em 0em",
    maxWidth: "1750px",
    width: "100%",
    minHeight: "calc(100vh - calc(187px + 2em))",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
      height: "auto",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    width: "100%",
    gap: "1em",
    marginBottom: "2em",
  },
  halfWidth: {
    width: "100%",
    height: "50px",
    position: "relative",
    background: theme.colorBackground,
    marginBottom: "2em",
    "& input": {
      fontSize: "1.5rem",
      color: theme.colorText,
      border: `1px solid ${theme.colorText}`,
      // boxSizing: "border-box",
      width: "100%",
      height: "50px",
      background: theme.colorBackground,
      // width: "100%",
      padding: "0 1em",
      "&:focus, &:hover": {
        border: `1px solid ${theme.colorPrimary}`,
        outline: "none",
        // fontSize: "2rem",
      },
    },
    "& span": {
      position: "absolute",
      textTransform: "uppercase",
      padding: "1.2em",
      pointerEvents: "none",
      color: theme.colorSecondary,
      left: "0",
      top: "0",
    },
    "&:hover $formLabel": {
      fontSize: "4rem",
    },
    // marginRight: "4em"
  },
  marginRight: {
    // marginRight: "3em",
  },
  fullWidth: {
    // width: "100%",
    height: "50px",
    marginBottom: "2em",
    background: theme.colorBackground,
  },
  nameCompanyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    marginBottom: "1em",
    width: "50%",
    "@media (max-width:750px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  fullNameContainer: {
    // position: "relative",
    width: "100%",
    height: "50px",
    background: theme.colorBackground,
  },

  successfulSubmit: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - calc(187 + 2em))",
    paddingBottom: "5em",
  },
  successfulSubmitText: {
    fontFamily: "Bebas",
    fontSize: "6rem",
    color: theme.colorTertiary,
    "@media(max-width:750px)": {
      fontSize: "4rem",
    },
  },
  successfulSubmitText2: {
    fontFamily: "Bebas",
    fontSize: "6rem",
    color: theme.colorPrimary,
    "@media(max-width:750px)": {
      fontSize: "4rem",
    },
  },
  button: {
    alignSelf: "flex-start",
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1em",

  },
  optionsContainer: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-between",
    gap: "1em",
    "@media (max-width:750px)": {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    }
  },
  option: {
    display: "flex",
    flex: "1",
    fontFamily: "Bebas",
    fontSize: "2rem",
    textAlign: "center",
    border: `1px solid ${theme.colorText}`,
    padding: "1em 2em",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      border: `1px solid ${theme.colorPrimary}`,
      backgroundColor: theme.colorBackgroundDarker,
    },
    "&:active": {
      scale: "0.9",
    },
    "@media (max-width:750px)": {
      width: "100%",
    }
  },
  optionSelected: {
    fontFamily: "Bebas",
    textAlign: "center",
    fontSize: "2rem",
    flex: "1",
    border: `1px solid ${theme.colorPrimary}`,
    padding: "1em 2em",
    cursor: "pointer",
    transition: "all .25s ease-in-out",
    backgroundColor: theme.colorBackgroundDarker,
  },
  optionUnderline: {
    width: "100%",
    transition: "all .25s ease-in-out",
    backgroundColor: theme.colorPrimary,
    height: "4px",
  },
  optionUnderlineTransparent: {
    width: "100%",
    transition: "all .2s ease-in-out",
    backgroundColor: "transparent",
    height: "4px",
  },
  optionOuterContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    gap: "1em",
  },
  reviewInputs: {
    fontFamily: "Source Sans",
    fontSize: "1.5rem", 
  },
  reviewInputLabel: {
    fontFamily: "Bebas",
    fontSize: "1.5rem",
    color: theme.colorPrimary,
  },
  changeStepContainer: {
    display: "flex",
    gap: "1em",
    flexWrap: "wrap",
  },
  changeStep: {
    fontFamily: "Bebas",
    fontSize: "1.5rem",
    textAlign: "center",
    border: `1px solid ${theme.colorText}`,
    padding: ".5em 2em",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      border: `1px solid ${theme.colorPrimary}`,
      backgroundColor: theme.colorBackgroundDarker,
    }
  },
  stepTitle: {
    fontFamily: "Bebas",
    fontSize: "2rem",
    color: theme.colorTertiary,
    borderBottom: `1px solid ${theme.colorTertiary}`,
    alignSelf: "flex-start",
    paddingBottom: ".25em",
  },
  mainStepContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  stepImage: {
    height: "100%",
    maxHeight: "400px",
    "@media (max-width:750px)": {
      display: "none",
    }
  },
  imageContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:750px)": {
      display: "none",
    }
  },
  errorMessage: {
    fontFamily: "Source Sans",
    fontSize: "1.25rem",
    color: "crimson",
    marginTop: ".25em",
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  }
}));

function MultiStepFormPage() {
  const classes = useStyles();

  const steps = ["Step1", "Step2", "Step3", "Step4", "Step5"];
  const [currentStep, setCurrentStep] = React.useState(steps[0]);
  const [currentOption, setCurrentOption] = React.useState("Option 1");
  const [reachedTheEnd, setReachedTheEnd] = React.useState(false);

  const validationSchema = object().shape({
    name: string().required().label("Full Name"),
    email: string().required().email().label("Email"),
    company: string().required().label("Company"),
  });

  const validationSchema3 = object().shape({
    testinput1: string().required().label("Test Input 1"),
  });
  const validationSchema2 = object().shape({
  });
  const validationSchema4 = object().shape({
  });
  const validationSchema5 = object().shape({
  });

  const validationSchemas = [validationSchema, validationSchema2, validationSchema3, validationSchema4, validationSchema5]

  const {
    register,
    handleSubmit,
    trigger,
    formState,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas[steps.indexOf(currentStep)]),
  });

  const currentFormValues = getValues();

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([data, { currentOption: currentOption }]),
    });
    window.scrollTo(0, 0);
    return response.json();
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    const inputValid = await trigger();
    console.log(inputValid)
    if(inputValid) {
      setCurrentStep(steps[steps.indexOf(currentStep) + 1]);
      window.scrollTo(0, 0);
      if (currentStep === steps[steps.length - 2]) {
        setReachedTheEnd(true);
      }
    }
  }

  const handlePrevStep = (e) => {
    e.preventDefault();
    setCurrentStep(steps[steps.indexOf(currentStep) - 1]);
    window.scrollTo(0, 0);
  }

  const skipToEnd = (e) => {
    e.preventDefault();
    setCurrentStep(steps[steps.length - 1]);
    window.scrollTo(0, 0);
  }

  return (
    <Fragment>
      <BackgroundText />
      <motion.div
        className={classes.mainContainer}
        initial={{ translateX: -100, opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 100 }}
        viewport={{ once: true }}
      >
        {!formState.isSubmitSuccessful && (
          <Fragment>
          </Fragment>
        )}

        {!formState.isSubmitSuccessful && (

          <form
            className={classes.form}
            onSubmit={handleSubmit((data) =>
              postData("/api/form-example/submit", data)
            )}
          >

          {currentStep === "Step1" && (
            <>
            <BigPageHeading heading="This is an example of a Multi-Step form. Follow the steps until the end." />
            <div className={classes.mainStepContainer}>
              <motion.div className={classes.nameCompanyContainer}
                initial={{ translateX: -100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
              >
                <AboutUsPageHeroHeading noImage width100 subHeading="Enter your contact information. (This is for demo purposes only and no information will be saved.)" />
                <div className="inputBox">
                  <input autoComplete="name" label="Full Name" required {...register("name", { required: true })} />
                  <span className={classes.formLabel}>Full Name *</span>
                  {errors.name && <p className={classes.errorMessage}>{errors.name.message}</p>}
                </div>
                <div className="inputBox">
                  <input
                    autoComplete="email"
                    // type="email"
                    required
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                  />
                  <span>Email *</span>
                  {errors.email && <p className={classes.errorMessage}>{errors.email.message}</p>}
                </div>
                <div className="inputBox">
                  <input autoComplete="organization" required {...register("company")} />
                  <span>Company *</span>
                  {errors.company && <p className={classes.errorMessage}>{errors.company.message}</p>}
                </div>
                <motion.div className={classes.buttonContainer}
                  initial={{ translateX: -100, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 100 }}
                  viewport={{ once: true }}
                >
                </motion.div>
                <div onClick={handleNextStep} className={`button-52 ${classes.button}`}>Next Step</div>
                {reachedTheEnd && <div onClick={skipToEnd} className={`button-52 ${classes.button}`}>Skip to the End</div>}
              </motion.div>
              <motion.div
                className={classes.imageContainer}
                initial={{ translateX: 100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
              >
                <motion.img 
                  initial={{ translateX: 100, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 100 }}
                  viewport={{ once: true }}
                  className={classes.stepImage}
                  src={laptopPic}
                  alt="Laptop Illustration" 
                />
              </motion.div>
            </div>
            </>
          )}


          {currentStep === "Step2" && (
            <>
            <BigPageHeading heading="This is step 2 of our Multi-Step form example. Follow the steps until the end." />
            <motion.div className={classes.nameCompanyContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
            <AboutUsPageHeroHeading noImage width100 subHeading="Choose one of the options below" />
              <div className={classes.optionsContainer}>
                <div className={classes.optionOuterContainer}><div className={currentOption === "Option 1" ? classes.optionSelected : classes.option} onClick={() => {setCurrentOption("Option 1")}}>Option 1</div><div className={currentOption === "Option 1" ? classes.optionUnderline : classes.optionUnderlineTransparent}></div></div>
                <div className={classes.optionOuterContainer}><div className={currentOption === "Option 2" ? classes.optionSelected : classes.option} onClick={() => {setCurrentOption("Option 2")}}>Option 2</div><div className={currentOption === "Option 2" ? classes.optionUnderline : classes.optionUnderlineTransparent}></div></div>
                <div className={classes.optionOuterContainer}><div className={currentOption === "Option 3" ? classes.optionSelected : classes.option} onClick={() => {setCurrentOption("Option 3")}}>Option 3</div><div className={currentOption === "Option 3" ? classes.optionUnderline : classes.optionUnderlineTransparent}></div></div>
                <div className={classes.optionOuterContainer}><div className={currentOption === "Option 4" ? classes.optionSelected : classes.option} onClick={() => {setCurrentOption("Option 4")}}>Option 4</div><div className={currentOption === "Option 4" ? classes.optionUnderline : classes.optionUnderlineTransparent}></div></div>
                <div className={classes.optionOuterContainer}><div className={currentOption === "Option 5" ? classes.optionSelected : classes.option} onClick={() => {setCurrentOption("Option 5")}}>Option 5</div><div className={currentOption === "Option 5" ? classes.optionUnderline : classes.optionUnderlineTransparent}></div></div>
              </div>
            </motion.div>
            <motion.div className={classes.buttonContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
              <div onClick={handlePrevStep} className={`button-52 ${classes.button}`}>Go Back</div>
              <div onClick={handleNextStep} className={`button-52 ${classes.button}`}>Next Step</div>
              {reachedTheEnd && <div onClick={skipToEnd} className={`button-52 ${classes.button}`}>Skip to the End</div>}
            </motion.div>
            </>
          )}


          {currentStep === "Step3" && (
            <>
            <BigPageHeading heading="This is step 3 of our Multi-Step form example. Follow the steps until the end." />
            <div className={classes.mainStepContainer}>

            <motion.div className={classes.nameCompanyContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
            <AboutUsPageHeroHeading noImage width100 subHeading="You can have any number of inputs, and they can be required or optional." />
              <div className="inputBox">
                <input required {...register("testinput1", { required: true })} />
                <span className={classes.formLabel}>Test Input 1 *</span>
                {errors.testinput1 && <p className={classes.errorMessage}>{errors.testinput1.message}</p>}
              </div>
              <div className="inputBox">
                <input required {...register("testinput2", { required: false })} />
                <span className={classes.formLabel}>Test Input 2</span>
              </div>
              <div className="inputBox">
                <input required {...register("testinput3", { required: false })} />
                <span className={classes.formLabel}>Test Input 3</span>
              </div>
              <div className="inputBox">
                <input required {...register("testinput4", { required: false })} />
                <span className={classes.formLabel}>Test Input 4</span>
              </div>
              <div className="inputBox">
                <input required {...register("testinput5", { required: false })} />
                <span className={classes.formLabel}>Test Input 5</span>
              </div>
            <motion.div className={classes.buttonContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
              <div onClick={handlePrevStep} className={`button-52 ${classes.button}`}>Go Back</div>
              <div onClick={handleNextStep} className={`button-52 ${classes.button}`}>Next Step</div>
              {reachedTheEnd && <div onClick={skipToEnd} className={`button-52 ${classes.button}`}>Skip to the End</div>}
            </motion.div>
            </motion.div>
            <div className={classes.imageContainer}>
              <motion.img 
                initial={{ translateX: 100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
                className={classes.stepImage}
                src={rocketPic}
                alt="Rocket Ship Illustration" 
              />
            </div>
            </div>
            </>
          )}


          {currentStep === "Step4" && (
            <>
            <BigPageHeading heading="This is step 4 of our Multi-Step form example. Follow the steps until the end." />
            <AboutUsPageHeroHeading noImage subHeading="You can have steps in the form that require no input from the user, and are simply informational like this one." />
            <motion.div className={classes.buttonContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
              <div onClick={handlePrevStep} className={`button-52 ${classes.button}`}>Go Back</div>
              <div onClick={handleNextStep} className={`button-52 ${classes.button}`}>Next Step</div>
              {reachedTheEnd && <div onClick={skipToEnd} className={`button-52 ${classes.button}`}>Skip to the End</div>}
            </motion.div>
            </>
          )}


          {currentStep === "Step5" && (
            <>
            <BigPageHeading heading="This is the final step of our Multi-Step form example. Press the button to submit the form!" />
            <div className={classes.mainStepContainer}>
              <motion.div className={classes.nameCompanyContainer}
                initial={{ translateX: -100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
              >
              <AboutUsPageHeroHeading noImage width100 subHeading="When you reach the end of the form, you can have a step that reviews all of the information that was entered by the user before final submission. You can also go back to any of the previous steps to edit them." />
              <motion.div className={classes.changeStepContainer}
                initial={{ translateX: -100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
              >
                <div className={classes.changeStep} onClick={() => {setCurrentStep(steps[0]); window.scrollTo(0, 0)}}>Step 1</div>
                <div className={classes.changeStep} onClick={() => {setCurrentStep(steps[1]); window.scrollTo(0, 0)}}>Step 2</div>
                <div className={classes.changeStep} onClick={() => {setCurrentStep(steps[2]); window.scrollTo(0, 0)}}>Step 3</div>
                <div className={classes.changeStep} onClick={() => {setCurrentStep(steps[3]); window.scrollTo(0, 0)}}>Step 4</div>
              </motion.div>
              <motion.div 
              className={classes.stepContainer}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
              >
                <h2 className={classes.stepTitle}>Step 1</h2>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Name:</span> {`${currentFormValues.name}`} </p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Email:</span> {`${currentFormValues.email}`} </p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Company:</span> {`${currentFormValues.company}`}</p>
                <h2 className={classes.stepTitle}>Step 2</h2>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Option:</span> {`${currentOption}`} </p>
                <h2 className={classes.stepTitle}>Step 3</h2>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Test Input 1: </span> {`${currentFormValues.testinput1}`}</p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Test Input 2 (Optional): </span>{currentFormValues.testinput2 ? `${currentFormValues.testinput2}`: "No Input"}</p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Test Input 3 (Optional): </span>{currentFormValues.testinput3 ? `${currentFormValues.testinput3}`: "No Input"}</p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Test Input 4 (Optional): </span>{currentFormValues.testinput4 ? `${currentFormValues.testinput4}`: "No Input"}</p>
                <p className={classes.reviewInputs}><span className={classes.reviewInputLabel}>Test Input 5 (Optional): </span>{currentFormValues.testinput5 ? `${currentFormValues.testinput5}`: "No Input"}</p>
              </motion.div>
                <motion.div className={classes.buttonContainer}
                  initial={{ translateX: -100, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 100 }}
                  viewport={{ once: true }}
                >
                  <div onClick={handlePrevStep} className={`button-52 ${classes.button}`}>Go Back</div>
                  <Button text="Submit" onClick={handleSubmit} color />
                </motion.div>
              </motion.div>
              <div className={classes.imageContainer}>
              <motion.img 
                initial={{ translateX: 100, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 100 }}
                viewport={{ once: true }}
                className={classes.stepImage}
                src={gearPic}
                alt="Rocket Ship Illustration" 
              />
            </div>
            </div>
            </>
          )}


            {formState.isSubmitSuccessful && formState.isSubmitted && (
              <div className="success">Form submitted successfully</div>
            )}
          </form>
          
        )}
        {formState.isSubmitSuccessful && formState.isSubmitted && (
          <motion.div className={classes.successfulSubmit}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{ once: true }}
            >
            <p className={classes.successfulSubmitText}>
              You've reached the end of the Multi-Step form example! Your form was submitted to the server. Don't worry, the information isn't persisted long term.
            </p>
            <p className={classes.successfulSubmitText2}>
              If you want a form like this on your website, get in touch with us!
            </p>
            <Link to="/contact">
              <Button text="Get In Touch" color />
            </Link>
          </motion.div>
        )}
      </motion.div>
    </Fragment>
  );
}

export default MultiStepFormPage;
