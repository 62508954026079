import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import Button from "./Button";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "750px",
    // margin: "1em",
    // boxShadow: "2px 2px 2px #ffeeff",
    borderRadius: "30px",
    "@media (max-width: 750px)": {
      width: "100%",
      minHeight: "750px",
      height: "100%",
      marginLeft: "0px",
      marginRight: "0px",
      // flexDirection: "column",
    },
  },
  overlay: {
    borderRadius: "30px",
    background: "black",
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: "1",
    opacity: "75%",
    "&:hover": {
      opacity: "75%",
    },
  },
  projectName: {
    // position: "relative",
    fontFamily: "Bebas",
    fontSize: "3.25rem",
    zIndex: "2",
    // textAlign: "center",
    // marginBottom: ".5em",
    // marginLeft: "1rem",
    // marginRight: "1rem",
  },
  description: {
    fontFamily: "Source Sans",
    // marginLeft: "1rem",
    // marginRight: "1rem",
    fontSize: "1.5rem",
    zIndex: "2",
    "&:hover": {},
  },
  textContainer: {
    textAlign: "right",
    height: "100%",
    width: "30%",
    display: "flex",
    gap: ".5em",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    "@media (max-width:750px)": {
      width: "100%",
    }
    // padding: "1em",
  },
  textContainerRight: {
    height: "100%",
    width: "30%",
    display: "flex",
    gap: ".5em",
    flexDirection: "column",
    textAlign: "left",
    // alignItems: "flex-end",
    justifyContent: "center",
    // padding: "1em",
    "@media (max-width:750px)": {
      width: "100%",
    }
  },
  image: {
    width: "70%",
    height: "100%",
    objectFit: "contain",
    "@media (max-width:750px)": {
      width: "100%",
    }
  },
  link: {
    display: "flex",
    width: "100%",
    gap: "1em",
    "@media (max-width:750px)": {
      flexDirection: "column",

    }
  },
  technologyContainer: {
    display: "flex",
    gap: "1em",
  },
  technology: {
    fontFamily: "Bebas",
    fontSize: "1.25rem",
    color: theme.colorPrimary,
  },
  whatWeDid: {
    fontFamily: "Bebas",
    color: theme.colorSecondary
  }
}));

function PortfolioItemPreview(props) {
  const classes = useStyles();
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <motion.li
      // style={{ background: `url(${props.image})`, backgroundSize: "cover" }}
      className={classes.mainContainer}
      initial={props.right ? { translateX: 100, opacity: 0 } : { translateX: -100, opacity: 0 }}
      whileInView={{ translateX: 0, opacity: 100 }}
      transition={{ duration: .5}}
      viewport={{once: true}}
    >
      <div className={classes.link}>
        {props.left ? <img className={classes.image} src={props.image} alt="Project preview image" /> : ""}
        {/* <div className={classes.overlay}></div> */}
        <div
          className={
            props.left ? classes.textContainer : classes.textContainerRight
          }
        > <div className={classes.technologyContainer}>
          {props.technologies ? props.technologies.map((technology) => { return <div className={classes.technology}>{technology}</div>}) : ""}
        </div>
          <p className={classes.whatWeDid}>Design - Development - Deployment</p>
          <h1 className={classes.projectName}>{props.projectName}</h1>
          <p className={classes.description}>{props.description}</p>
          <a
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button text="View Project" />
          </a>
        </div>
        {props.right ? <img className={classes.image} src={props.image} alt="Project preview image" /> : ""}
      </div>
    </motion.li>
  );
}

export default PortfolioItemPreview;
