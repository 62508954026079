import React from "react";
import { createUseStyles } from "react-jss";
import SectionHeader from "./SectionHeader";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainTitle: {
    fontFamily: "Bebas",
    marginBottom: ".1em",
    fontSize: "6rem",
    color: theme.colorPrimary,
    "@media (max-width:750px)": {
      fontSize: "4rem",
    }
  },
}));

function BigPageHeading(props) {
  const classes = useStyles();
  return (
    <motion.h2
      className={classes.mainTitle}
      initial={{ opacity: 0, translateY: -100 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5, delay: .35 }}
    >
      {props.heading}
    </motion.h2>
  );
}

export default BigPageHeading;
