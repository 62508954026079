import React from "react";
import { createUseStyles } from "react-jss";
import { motion, AnimatePresence } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1em",
    paddingLeft: "0",
    paddingRight: "0",
    maxWidth: "1750px",
    fontFamily: "Bebas",
    fontSize: "2rem",
    cursor: "pointer",
    transition: "all 0.5s ease-in-out",
    background: theme.colorBackground,
    // border: "1px solid white",
    borderBottom: `1px solid ${theme.colorPrimary}`,
    color: theme.colorPrimary,
    width: "100%",
    "&:hover $iconClose, &:hover $iconOpen": {
      transform: "rotate(360deg)",
    },
    "&:hover $accordionHeader": {
      borderBottom: `4px solid ${theme.colorPrimary}`

    }

  },
  accordionHeader: {
    display: "flex",
    background: theme.colorBackground,
    borderBottom: "4px solid transparent",
    paddingBottom: ".1em",
    // width: "100%",
    transition: "all 0.5s ease-in-out",
  },
  accordionBody: {
    background: theme.colorBackground,
    paddingTop: ".5em",
    color: theme.colorText,
    fontSize: "1.5rem",
    fontFamily: "Source Sans",
    transition: "all 0.5s ease-in-out",
  },
  iconClose: {
    transition: "all 0.5s ease-in-out",
    color: theme.colorSecondary
  },
  iconOpen: {
    transition: "all 0.5s ease-in-out",
  }
}));

function FAQItem(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles();
  const handleOpenClose = () => {
    isOpen === false ? setIsOpen(true) : setIsOpen(false);
  }
  React.useEffect(() => {
  }, [isOpen])

  return(
    <motion.div 
      className={classes.mainContainer} 
      onClick={handleOpenClose}
      initial={{ translateX: -100, opacity: 0 }}
      whileInView={{ translateX: 0, opacity: 100 }}
      transition={{delay: 0.1 * props.delay, duration: 0.25}}
      viewport={{once:true}}
    >
      <div style={{display: "flex", paddingRight: "1em", justifyContent: "space-between"}}>
        <h1 className={classes.accordionHeader}>{props.question}</h1>
        {isOpen ? <p className={classes.iconClose}>-</p> : <p className={classes.iconOpen}>+</p>}
      </div>
      <AnimatePresence>
        {isOpen && <motion.p className={classes.accordionBody} 
        initial={{ opacity: 0, translateX: -50 }}
        transition={{duration: 0.25}}
        animate={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, height: 0 }}>{props.answer}</motion.p>}
      </AnimatePresence>
    </motion.div>
  );
}

export default FAQItem;;