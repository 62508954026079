import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import BigPageHeading from "./BigPageHeading";
import BackgroundText from "./BackgroundText";
import pic from "../pics/chairanddesk.webp";
import Button from "./Button";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    color: theme.colorText,
    marginTop: "calc(187px + 2em)",
    marginBottom: "2em",
    minHeight: "50dvh",
    maxWidth: "1750px",
    width: "100%",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
    },
  },
  innerContainer: {
    display: "flex",
    position: "relative",
    minHeight: "750px",
    "@media (max-width:750px)": {
      // flexDirection: "column-reverse",
      gap: "1em",
      flexDirection: "column",
    },
  },
  paragraph: {
    fontFamily: "Source Sans",
    fontSize: "1.75rem",
  },
  paragraphContainer: {
    width: "50%",
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    gap: "1em",
    "@media (max-width:750px)": {
      width: "100%",
    },
  },
  emailLink: {
    cursor: "pointer",
    borderBottom: "4px solid transparent",
    transition: "all .25s ease-in-out",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
  },
  image: {
    position: "absolute",
    maxHeight: "1050px",
    right: "0",
    zIndex: "-10",
    overflow: "hidden",
    "@media (max-width:750px)": {
      objectFit: "contain",
      maxHeight: "300px",
      position: "static",
    },
  },
}));

function CareersPage() {
  const classes = useStyles();
  return (
    <Fragment>
      <BackgroundText />
      <div className={classes.mainContainer}>
        <BigPageHeading heading="Careers" />
        <div className={classes.innerContainer}>
          <motion.div
            className={classes.paragraphContainer}
            initial={{ opacity: 0, translateX: -100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ once: true }}
          >
            <p className={classes.paragraph}>
              If you're enthusiastic about web development and believe in the
              power of digital experiences, we'd love to hear from you. We offer
              an engaging and dynamic work environment that values
              collaboration, creativity, and continuous learning.
            </p>
            <p className={classes.paragraph}>
              Though we don't have specific job openings at the moment, we
              always welcome inquiries from talented individuals. If you're
              interested in our work and believe you'd make a great addition to
              our team, drop us an email with a brief introduction about
              yourself and your resume attached.
            </p>
            <p className={classes.paragraph}>
              Email us at:{" "}
              <a
                className={classes.emailLink}
                href="mailto:careers@studiowhitetiger.com"
              >
                careers@studiowhitetiger.com
              </a>
            </p>
            <p className={classes.paragraph}>
              For all other inquiries you can contact us using our contact form:
            </p>
            <Link to="/contact">
              <Button text="Contact Us" color />
            </Link>
          </motion.div>
          <motion.img
            className={classes.image}
            src={pic}
            initial={{ opacity: 0, translateX: 100 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ x: { duration: 1 }, default: { ease: "linear" } }}
          ></motion.img>
        </div>
      </div>
    </Fragment>
  );
}

export default CareersPage;
