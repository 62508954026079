import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import Logo from "../tigerlogowhite.svg";
import Button from "./Button";
import { motion, useScroll } from "framer-motion";
import hamburgerMenuIcon from "../pics/hamburgermenu-good.svg"
import NavModal from "./NavModal";

const useStyles = createUseStyles((theme) => ({
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    opacity: "75",
    zIndex: "100",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  mainContainer: {
    display: "flex",
    color: theme.colorText,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1750px",
    fontSize: "1.5em",
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
    "@media (max-width:750px)": {
      marginLeft: "0",
      paddingTop: ".75em",
      paddingBottom: ".75em",
    },
  },
  title: {
    fontSize: "1.5rem",
    cursor: "pointer",
    fontFamily: "Bebas",
    transition: "border .25s ease-in-out",
    borderBottom: "4px solid transparent",
  },
  leftContainer: {
    display: "flex",
    "&:hover $title": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
    "@media (max-width:750px)": {
      marginLeft: "0",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2em",
    "@media (max-width:750px)": {
      display: "none",
    },
  },
  menuItem: {
    cursor: "pointer",
    fontFamily: "Bebas",
    fontSize: "1.5rem",
    borderBottom: "4px solid transparent",
    transition: "border .25s ease-in-out",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
  },

  logo: {
    height: "75px",
    "@media (max-width:750px)": {
      height: "35px",
    },
  },
  separator: {
    width: "2px",
    height: "25px",
    background: theme.colorPrimary,
  },
  hamburgerMenu: {
    cursor: "pointer",
    maxHeight: "30px",
    
    
    transition: "all .25s ease-in-out",
    "@media (min-width: 750px)": {
      display: "none",
    },
    "&:active": {
      color: theme.colorPrimary,
    },
    "&:hover": {
      color: theme.colorPrimary,
    },
  },
}));

function CustomHeader() {
  const classes = useStyles();
  const { scrollY } = useScroll();
  const [hidden, setHidden] = React.useState(false);
  const [superHidden, setSuperHidden] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const variants = {
    visible: { backgroundColor: "rgb(1, 9, 17, 0.8)", y: 0, opacity: 100 },
    hidden: {
      backgroundColor: "rgb(0,0,0,0)",
      y: -25,
      pointerEvents: "none",
      opacity: 0,
      duration: 0.5,
    },
  };

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 240 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
    if (scrollY?.current < 50) {
      setSuperHidden(true);
    } else if (scrollY?.current > 50) {
      setSuperHidden(false);
    }
  }

  React.useEffect(() => {
    return scrollY.onChange(() => update());
  });

  React.useEffect(() => {
  }, [menuOpen]);

  return (
    <Fragment>
      {menuOpen ? <NavModal handleClose={handleClose} /> : ""}
      <motion.div
        className={classes.outerContainer}
        variants={variants}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        animate={hidden ? "hidden" : "visible"}
        // transition={{ duration: 1 }}
        style={superHidden ? { backgroundColor: "rgb(0,0,0,0)" } : {}}
      >
        <div
          className={classes.mainContainer}
          style={hidden ? { opacity: 0 } : { opacity: 100 }}
        >
          <div className={classes.leftContainer}>
            <Link
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                gap: ".5em",
              }}
              to="/"
            >
              <img className={classes.logo} src={Logo} alt="Studio White Tiger logo" />
              <h2 className={classes.title}>Studio White Tiger</h2>
            </Link>
          </div>
          <div className={classes.rightContainer}>
            <p className={classes.menuItem}>
              <Link to="/services">Services</Link>
            </p>
            <div className={classes.separator} />
            <Link to="/our-work">
              <p className={classes.menuItem}>Our Work</p>
            </Link>
            <div className={classes.separator} />
            <p className={classes.menuItem}>
              <Link to="/about-us">About Us</Link>
            </p>
            <Link to="/contact">
              <Button className={classes.menuItem} text="Let's Talk" color />
            </Link>
          </div>
          <img
            className={classes.hamburgerMenu}
            src={hamburgerMenuIcon}
            onClick={() => {
              setMenuOpen(true);
            }}
          />
        </div>
      </motion.div>
    </Fragment>
  );
}

export default CustomHeader;
