import React from "react";
import { createUseStyles } from "react-jss";
import SectionHeader from "./SectionHeader";
import { Link } from "react-router-dom";
import Button from "./Button";

const useStyles = createUseStyles({
  mainContainer: {
    padding: "2em",
    width: "100%",
    maxWidth: "1750px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

  }
});

function NotFoundPage() {
  const classes = useStyles()
  return(
    <div className={classes.mainContainer}>
      <SectionHeader header="404 PAGE NOT FOUND" />
      <Link to="/"><Button text="Return Home" /></Link>

    </div>

  );
}

export default NotFoundPage;