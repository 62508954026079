import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import arrow from "../pics/arrow.svg";



const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // position: "relative",
    width: "300px",
    height: "600px",
    border: `4px solid ${theme.colorPrimary}`,
    padding: "3em 2em",
    transition: "all 0.25s ease-in-out",
    "&:hover $body": {
      display: "block",
      height: "250px",
      opacity: "100",
      marginTop: "1em",
      paddingTop: "1em",
    },
    "&:hover $arrow": {
      transform: "rotate(-270deg)",
    },
    "@media(max-width: 750px)": {
      width:"100%"
    }
  },
  title: {
    display: "flex",
    gap: ".25em",
    fontFamily: "Bebas",
    fontSize: "2.5rem",
  },
  body: {
    pointerEvents: "none",
    transition: "all 0.5s ease-in-out",
    fontFamily: "Source Sans",
    fontSize: "1.5rem",
    opacity: "0",
    height: "0",
    borderTop: `4px solid ${theme.colorPrimary}`,
    "&:hover": {
      // display: "flex",
    },
  },
  number: {
    fontFamily: "Bebas",
    fontSize: "2rem"
  },
  image: {
    height: "125px",
    width: "125px"
  },
  arrow: {
    height: "20px",
    transform: "rotate(-90deg)",
    transition: "all 0.5s ease-in-out",

  }
}));

function ServiceItemPreview(props) {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      initial={{ opacity: 0, translateX: -100 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      viewport={props.once ? { once: true } : {}}
      transition={{ delay: 0.15 * props.delay }}
    >
    {/* <p>test</p> */}
    <motion.img 
      className={classes.image} 
      initial={{ opacity: 0, translateY: -100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      viewport={props.once ? { once: true } : {}}  
      transition={{ delay: 0.25 * props.delay }}
      src={props.image}
      ></motion.img>
    <div>
      <h4 className={classes.number}>0{props.delay}.</h4>
      <h3 className={classes.title}>{props.title}<span><img className={classes.arrow} src={arrow} /></span></h3>
      <p className={classes.body}>{props.text}</p>
    </div>
    </motion.div>
  );
}

export default ServiceItemPreview;
