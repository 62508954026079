import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import arrow from "../pics/arrow.svg";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    color: theme.colorText,
    flexDirection: "column",
    width: "33.333333%",
    border: `1px solid ${theme.colorPrimary}`,
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    height: "650px",
    fontFamily: "Bebas",
    paddingLeft: "1em",
    paddingRight: "1em",
    justifyContent: "space-between",
    transition: "all .25s ease-in-out",
    // alignItems: "center",
    "@media (max-width:750px)": {
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      backgroundColor: theme.colorBackgroundDarkerOpacity50,
      paddingBottom: "2em",
    },
    "&:hover $arrow":  {
    },
    "&:hover $title, &:hover $number": {
      color: theme.colorPrimary,
    }
  },
  number: {
    fontSize: "10rem",
    transition: "all .25s ease-in-out",
    "@media(max-width:750px)": {
      // fontSize: "6rem",
    },
  },
  title: {
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
    transition: "all .25s ease-in-out",
    "@media(max-width:750px)": {
      fontSize: "2.5rem",
      marginBottom: ".5em",
      paddingBottom: ".5em",
      borderBottom: `1px solid ${theme.colorPrimary}`,
    },
  },
  offering: {
    display: "flex",
    fontSize: "1.75rem",
    cursor: "pointer",
    borderBottom: "4px solid transparent",
    transition: "all .25s ease-in-out",
    "&:hover": {
      paddingLeft: "1em",
      color: theme.colorPrimary,
      borderBottom: `4px solid ${theme.colorPrimary}`,
    },
    "&:hover $arrow": {
      color: theme.colorPrimary,
      opacity: "100",
      transform: "rotate(320deg)",
    },
  },
  arrow: {
    transition: "all .5s ease-in-out",
    color: "transparent",
    opacity: "0",
    maxHeight: "25px",
    marginLeft: ".25em",
  },
  topContainer: {
    cursor: "pointer",
    "&:hover $arrow": {
      opacity: "100",
      color: theme.colorPrimary,
      transform: "rotate(320deg)",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
}));

function ServicePreview(props) {
  const classes = useStyles();
  const [items, setItems] = useState([""]);
  useEffect(() => {
    setItems(props.items);
  }, [items]);
  return (
    <div className={classes.mainContainer}>
      <motion.div
        transition={{
          x: { duration: 5 },
          default: { ease: "linear" },
        }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className={classes.topContainer}
      >
      <Link to="/services">

        <motion.h1
          className={classes.number}
          initial={{ translateY: -50, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
        >
          {props.number}
        </motion.h1>
        <motion.h2
          className={classes.title}
          initial={{ translateX: -50, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          viewport={{ once: true }}
        >
          {props.title}
          {/* <span> */}
            <img className={classes.arrow} src={arrow} />
          {/* </span> */}
        </motion.h2>
      </Link>
      </motion.div>
      <ul className={classes.list}>
        {items
          ? items.map((item) => {
              return (
                <div style={{ display: "flex" }}>
                  <Link to={`/${item[1]}`}>
                    <motion.li
                      transition={{
                        x: { duration: 5 },
                        default: { ease: "linear" },
                      }}
                      initial={{ translateX: -50, opacity: 0 }}
                      whileInView={{ translateX: 0, opacity: 1 }}
                      className={classes.offering}
                      viewport={{ once: true }}
                    >
                      {item[0]}
                      <span>
                        <img className={classes.arrow} src={arrow} />
                      </span>
                    </motion.li>
                  </Link>
                </div>
              );
            })
          : ""}
      </ul>
    </div>
  );
}

export default ServicePreview;
