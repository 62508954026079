
const servicesData = [
  {
    "slug": "website-design",
    "title": "Website Design",
    "subtitle": "Top Notch Web Designs",
    "description": "Our skilled design team will carefully craft a website that accentuates and elevates your business or start-up's image.",
    "heading1": "Responsive Web Design",
    "heading2": "User Experience and Interface",
    "heading3": "Customized Layouts and Templates",
    "paragraph1": "Our expert designers create responsive websites that adapt seamlessly to any device, ensuring a consistent and enjoyable experience for users across platforms.",
    "paragraph2": "We prioritize user experience and interface, crafting visually appealing and easy-to-navigate websites that engage and retain visitors.",
    "paragraph3": "We work closely with clients to develop customized layouts and templates that align with their brand identity, vision, and goals."
  },
  {
    "slug": "branding",
    "title": "Branding",
    "subtitle": "Make Your Brand Memorable",
    "description": "Our talented graphic designers create unique, professional logos that reflect your brand's essence and capture the attention of your target audience.",
    "heading1": "Conceptualization and Ideation",
    "heading2": "Versatile and Scalable Designs",
    "heading3": "Multiple Formats and Sizes",
    "paragraph1": "We collaborate with you to understand your business values and objectives, then develop logo concepts that resonate with your brand identity.",
    "paragraph2": "Our designers create versatile and scalable logo designs that look sharp across various mediums and platforms, ensuring your brand remains consistent.",
    "paragraph3": "We provide your logo in multiple formats and sizes, ensuring it's ready to be used for print, digital, and any other application you require."
  },
  {
    "slug": "content-design",
    "title": "Content Design",
    "subtitle": "Engaging and Purposeful Content",
    "description": "Our content design services focus on creating compelling, meaningful content that captivates your audience, communicates your message, and achieves your business goals.",
    "heading1": "Copywriting and Editing",
    "heading2": "Visual Content Design",
    "heading3": "Information Architecture",
    "paragraph1": "Our skilled copywriters and editors craft engaging and well-structured written content, tailored to your brand voice and target audience, that effectively conveys your message.",
    "paragraph2": "We design visually captivating content, including graphics, infographics, and videos, that resonates with your audience and supports your brand identity.",
    "paragraph3": "We help you organize and structure your content to optimize user experience, ensuring that your website is easy to navigate and that information is presented in a clear, concise manner."
  },
  {
    "slug": "custom-web-development",
    "title": "Custom Web Development",
    "subtitle": "Transforming Your Ideas Into Reality",
    "description": "Our team of expert developers delivers tailored web development solutions that meet the specific needs and goals of your business.",
    "heading1": "Full-Stack Development",
    "heading2": "API Integration and Customization",
    "heading3": "Future-Proof Solutions",
    "paragraph1": "Our full-stack developers have expertise in front-end and back-end technologies, ensuring seamless performance and functionality for your website.",
    "paragraph2": "We integrate and customize APIs to provide your users with a seamless experience, connecting your website with the tools and services your business relies on.",
    "paragraph3": "We stay up-to-date with the latest trends and technologies to develop future-proof solutions that grow and evolve with your business."
  },
  { 
    "slug": "internal-tools",
    "title": "Internal Tools",
    "subtitle": "Streamline Your Business Operations",
    "description": "Our experienced developers design and build custom internal tools that enhance productivity, automate processes, and improve decision-making within your organization.",
    "heading1": "Tailored Solutions for Your Needs",
    "heading2": "Seamless Integration with Existing Systems",
    "heading3": "Ongoing Support and Maintenance",
    "paragraph1": "We work closely with your team to understand your unique business requirements and develop bespoke internal tools that drive efficiency and growth.",
    "paragraph2": "Our developers ensure seamless integration of the custom tools with your existing systems, enhancing data flow and streamlining your business processes.",
    "paragraph3": "We provide ongoing support and maintenance for the internal tools we develop, ensuring they continue to meet your evolving business needs and remain up-to-date with industry standards."
  },
  {
    "slug": "static-websites",
    "title": "Static Websites",
    "subtitle": "Fast and Secure Web Solutions",
    "description": "Our team designs and develops lightning-fast static websites that are low-maintenance and highly secure, perfect for showcasing your business's online presence.",
    "heading1": "High-Speed Performance",
    "heading2": "Security and Reliability",
    "heading3": "Easy Deployment and Maintenance",
    "paragraph1": "Static websites are known for their blazing-fast performance, providing your users with a smooth and seamless browsing experience.",
    "paragraph2": "Static websites minimize the risk of security vulnerabilities, ensuring your site remains safe and reliable for your visitors.",
    "paragraph3": "With no need for complex server configurations or database management, deploying and maintaining static websites is a breeze."
  },
  {
    "slug": "mobile-development",
    "title": "Mobile Development",
    "subtitle": "Unlock the Potential of Mobile",
    "description": "We develop custom mobile applications for Android and iOS, offering seamless performance and exceptional user experience across all devices.",
    "heading1": "Android App Development",
    "heading2": "iOS App Development",
    "heading3": "Cross-Platform App Development",
    "paragraph1": "Our skilled Android developers create powerful, user-friendly apps that cater to the specific needs of your business. We use the latest tools and technologies to ensure your app stays up-to-date and relevant in a rapidly changing digital landscape.",
    "paragraph2": "Our team of experienced iOS developers design and build high-quality, secure, and visually stunning apps for iPhone and iPad users. We adhere to strict development standards and guidelines to ensure your app is well-received by Apple's App Store and your target audience.",
    "paragraph3": "In an increasingly mobile world, having an app that runs on multiple platforms is essential. We offer cross-platform app development services, leveraging popular frameworks like React Native and Flutter, to create versatile apps that perform consistently across devices."    
  },
  {
    "slug": "backend-development",
    "title": "Backend Development",
    "subtitle": "Powering Your Online Platforms",
    "description": "Our experienced backend developers build robust, scalable, and secure solutions that ensure your web and mobile applications run smoothly and efficiently.",
    "heading1": "Database Design and Management",
    "heading2": "API Development",
    "heading3": "Server and Infrastructure Optimization",
    "paragraph1": "We design and manage databases that store and retrieve data efficiently, allowing your applications to run seamlessly and handle high user loads.",
    "paragraph2": "Our backend developers create custom APIs that connect your web and mobile applications to the tools and services your business relies on.",
    "paragraph3": "We optimize your server and infrastructure setup to ensure your applications remain fast, reliable, and secure, even as your business grows."
  },
  {
    "slug": "seo-optimization",
    "title": "SEO Optimization",
    "subtitle": "Increase Your Online Visibility",
    "description": "Our data-driven SEO strategies improve your website's search engine rankings, driving organic traffic and helping you reach your target audience.",
    "heading1": "Keyword Research and Analysis",
    "heading2": "On-Page and Off-Page Optimization",
    "heading3": "SEO-Friendly Content",
    "paragraph1": "We perform in-depth keyword research and analysis to identify the search terms your target audience uses, optimizing your website to rank for those terms.",
    "paragraph2": "Our SEO experts implement on-page and off-page optimization techniques, improving your website's structure, content, and backlink profile.",
    "paragraph3": "We create high-quality, SEO-friendly content that engages your audience and establishes your brand as an industry authority, while also improving your search engine rankings."
  },
  {
    "slug": "deployment-options",
    "title": "Deployment Options",
    "subtitle": "Flexible Solutions for Your Business",
    "description": "We offer a variety of deployment options to suit your needs, including hosting, Docker, and other cloud-based solutions.",
    "heading1": "Managed Hosting",
    "heading2": "Docker Deployment",
    "heading3": "Custom Cloud Solutions",
    "paragraph1": "Our managed hosting services provide a hassle-free experience, ensuring your website remains fast, secure, and updated at all times.",
    "paragraph2": "We offer Docker deployment for your projects, providing a scalable and portable solution that allows for seamless updates and migration between environments.",
    "paragraph3": "We work with you to develop custom cloud-based solutions that suit the unique requirements of your business, maximizing performance, security, and scalability."
  },
  {
    "slug": "hosting-and-maintenance",
    "title": "Hosting and Maintenance",
    "subtitle": "Keeping Your Website Running Smoothly",
    "description": "Our hosting and maintenance services provide a reliable foundation for your website, ensuring optimal performance, security, and ongoing support.",
    "heading1": "Fast and Secure Hosting",
    "heading2": "Regular Updates and Backups",
    "heading3": "Dedicated Support",
    "paragraph1": "We offer fast and secure hosting solutions tailored to your website's requirements, ensuring a smooth and enjoyable experience for your visitors.",
    "paragraph2": "Our maintenance services include regular updates and backups, keeping your website secure and running smoothly at all times.",
    "paragraph3": "Our dedicated support team is available to assist you with any issues or questions, ensuring your website remains a reliable asset for your business."
  },
  {
    "slug": "full-service-a-la-carte",
    "title": "Full Service or A La Carte",
    "subtitle": "Tailored Solutions for Every Project",
    "description": "We offer full-service packages as well as a la carte options, allowing you to choose the services that best meet your specific needs and goals.",
    "heading1": "Full-Service Packages",
    "heading2": "A La Carte Options",
    "heading3": "Flexible Solutions",
    "paragraph1": "Our full-service packages include everything you need for a successful web or mobile project, from design and development to marketing and maintenance.",
    "paragraph2": "With our a la carte options, you can pick and choose the services you need, ensuring you only pay for what's necessary to achieve your objectives.",
    "paragraph3": "Whether you need an all-inclusive solution or just a specific service, we provide flexible solutions that can be tailored to your unique requirements and budget."
    
  },
  {
    "slug": "white-labeling-services",
    "title": "White-Labeling Services",
    "subtitle": "Extend Your Agency's Offerings",
    "description": "Our white-labeling services allow you to seamlessly offer our top-notch web and mobile development services to your clients under your own brand.",
    "heading1": "Expand Your Portfolio",
    "heading2": "Enhance Your Reputation",
    "heading3": "Streamline Your Workflow",
    "paragraph1": "With our white-labeling services, you can effortlessly expand your agency's portfolio, offering a broader range of solutions to your clients without additional overhead.",
    "paragraph2": "Our expert team delivers high-quality work that helps enhance your agency's reputation, positioning you as a one-stop shop for all your clients' digital needs.",
    "paragraph3": "We integrate seamlessly with your workflow and processes, providing ongoing support and collaboration to ensure smooth project delivery and client satisfaction."
  }
]

export default servicesData;