import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "react-router-dom";
import BigPageHeading from "./BigPageHeading";
import BackgroundText from "./BackgroundText";
import AboutUsPageHeroHeading from "./AboutUsPageHeroHeading";
import computerServerSVG from "../pics/gearblue.png"

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    color: theme.colorText,
    marginTop: "calc(187px + 2em)",
    paddingBottom: "4em",
    overflowX: "hidden",
    "@media (max-width:750px)": {
      marginTop: "calc(111px + 1em)",
    }
  },
  mainContentContainer: {
    display: "flex",
    gap: "1em",
    flexDirection: "column",
    maxWidth: "1750px",
  },
  paragraphRight: {
    fontSize: "2rem",
    fontFamily: "Source Sans",
    padding: "2em",
    position: "relative",
    right: "200px",
    background: theme.colorBackground,
    border: `4px solid ${theme.colorPrimary}`,
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    "@media (max-width:750px)": {
      position: "static",
      padding: "1em .5em"
    }
  },
  paragraphLeft: {
    fontSize: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    fontFamily: "Source Sans",
    padding: "2em",
    position: "relative",
    left: "200px",
    zIndex: "0",
    background: theme.colorBackground,
    border: `4px solid ${theme.colorPrimary}`,
    "@media (max-width:750px)": {
      position: "static",
      padding: "1em .5em"
    }
  },

  section: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
    "@media (max-width:750px)": {
      flexDirection: "column",
    }
  },
  sectionImage: {
    width: "50%",
    // height: "100%",
    zIndex: "-2",
    "@media (max-width:750px)": {
      width: "100%",
      objectFit: "cover"
    }
  },
  listTitle: {
    color: theme.colorPrimary,
    fontFamily: "Bebas",
  },
  listItem: {},
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".5em",
  },
  bigImage: {
    // height: "100%",
    width: "100%",
    minHeight: "500px",
    objectFit: "cover",
  },
  heroSection: {
    height: "100%",
    // minHeight: "100vh",
  },
}));

function AboutUsPage() {
  const classes = useStyles();
  return (
    <Fragment>
      <BackgroundText />

      <div className={classes.mainContainer}>
        <div className={classes.mainContentContainer}>
          <div className={classes.heroSection}>
            <BigPageHeading heading="Studio White Tiger: Where Creativity and Cutting-Edge Technology Collide" />
            {/* <motion.img
              className={classes.bigImage}
              initial={{ translateX: -100, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 100 }}
              viewport={{once: true}}
              src="https://images.unsplash.com/photo-1431540015161-0bf868a2d407?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            /> */}
            <AboutUsPageHeroHeading heading="Transforming Your Ideas into Impactful, Results-Driven Websites" subHeading="We create websites that empower businesses to grow, thrive, and dominate their market." image={computerServerSVG} imageMaxHeight="250px" callToAction/>
            <SectionHeader header="Our Story" />
          </div>
          <div className={classes.section}>
            <motion.img
              className={classes.sectionImage}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
              src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
              alt="People working on laptops"
            />
            <motion.div
              className={classes.paragraphRight}
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
            >
              <p className={classes.paragraph}>
                Studio White Tiger was founded by a group of passionate web
                designers and developers with a shared vision: to create
                outstanding digital solutions that deliver tangible results for
                businesses of all sizes.
              </p>
              <p className={classes.paragraph}>
                Our combined years of experience in the industry have allowed us
                to develop a deep understanding of what it takes to build
                exceptional websites and applications that not only look great
                but also perform flawlessly.
              </p>
            </motion.div>
          </div>
          <SectionHeader header="Our Services" />
          <div className={classes.section}>
            <motion.div
              className={classes.paragraphLeft}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              viewport={{ once: true }}
              transition={{ duration: .5 }}
            >
              <p>
                At Studio White Tiger, we pride ourselves on offering a
                comprehensive range of web design and development services that
                cater to your specific needs. Our core competencies include:
              </p>
              <ol className={classes.listContainer}>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>Web Design:</span> Craft
                  visually stunning and responsive websites that resonate with
                  your target audience and drive engagement.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>Web Development:</span>{" "}
                  Leveraging the power of React, NodeJS, and other advanced
                  technologies, we build robust and scalable web applications
                  that meet the highest standards of performance, security, and
                  reliability.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Wordpress Solutions:
                  </span>{" "}
                  We develop custom themes and plugins tailored to your unique
                  requirements, ensuring that your website is easy to manage and
                  maintain.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Mobile App Development:
                  </span>{" "}
                  We create high-quality mobile applications that deliver a
                  seamless and engaging user experience across all devices with
                  React Native.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    White Labeling Solutions:
                  </span>{" "}
                  We offer white labeling services to other agencies and
                  companies, allowing them to outsource their web design and
                  development needs while retaining full credit for the final
                  product.
                </li>
              </ol>
            </motion.div>
            <motion.img
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
              className={classes.sectionImage}
              src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
              alt="Laptop with IMac behind it."
            />
          </div>
          <SectionHeader header="Why Choose Studio White Tiger" />
          <div className={classes.section}>
            <motion.img
              className={classes.sectionImage}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
              src="https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
              alt="Close up of keyboard with code in the background."
            />
            <motion.div
              className={classes.paragraphRight}
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
            >
              <p>
                Our commitment to client satisfaction sets us apart from other
                web design agencies. When you partner with Studio White Tiger,
                you can expect:
              </p>
              <ol className={classes.listContainer}>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Friendly Collaboration:
                  </span>{" "}
                  Foster strong, long-lasting relationships with our clients,
                  providing expert guidance and support at every stage of the
                  process.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Experienced Professionals:
                  </span>{" "}
                  Deliver solutions that meet the unique needs of your business
                  with our team of seasoned designers and developers possessing
                  a wealth of experience in various industries.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>Timely Delivery:</span>{" "}
                  Ensure that your project is delivered on time, without
                  compromising on quality.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Transparent Communication:
                  </span>{" "}
                  Maintain open and honest communication with our clients
                  throughout the project, ensuring that you're always informed
                  and up to date on progress.
                </li>
                <li className={classes.listItem}>
                  <span className={classes.listTitle}>
                    Cutting-Edge Solutions:
                  </span>{" "}
                  Stay ahead of the curve by continuously refining our skills
                  and embracing new technologies, ensuring that your business
                  benefits from the latest and greatest in web design and
                  development.
                </li>
              </ol>
            </motion.div>
          </div>
          <SectionHeader header="Get Started" />
          <div className={classes.section}>
            <motion.div
              className={classes.paragraphLeft}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
            >
              <p>
                Ready to take your digital presence to new heights? Get in touch
                with us today to discuss your project, and let Studio White
                Tiger create a tailored solution that exceeds your expectations.
              </p>
              <Link to="/contact">
                <Button text="Contact Us" color/>
              </Link>
            </motion.div>
            <motion.img
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 100, translateX: 0 }}
              transition={{ duration: .5 }}
              viewport={{ once: true }}
              className={classes.sectionImage}
              src="https://images.unsplash.com/photo-1611095789391-947c8f94179c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80"
              alt="Men reaching out for a handshake"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AboutUsPage;
