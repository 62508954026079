import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {},
  whyChooseUsHeading: {
    fontFamily: "Bebas",
    color: theme.colorPrimary,
    fontSize: "1.75rem",
  },
  whyChooseUsText: {
    // width: "500px",
    fontSize: "1.75rem",
    // marginBottom: "1em",
    "@media (max-width:750px)": {},
  },
}));

function WhyChooseUsItem(props) {
  const classes = useStyles();
  return (
      <li className={classes.whyChooseUsText}>
        <span className={classes.whyChooseUsHeading}>{props.heading}: </span>
        {props.description}
      </li>
  );
}

export default WhyChooseUsItem;
