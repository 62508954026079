import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import arrow from "../pics/arrow.svg";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "6em",
    padding: "1em",
    paddingTop: "4em",
    paddingBottom: "4em",
    // background: "#010911",
    borderTop: `1px solid ${theme.colorPrimary}`,
    cursor: "pointer",
    transition: "all .25s ease-in-out",
    "&:hover": {
      padding: "4em 4em",
      backgroundColor: theme.colorTextOpacity80,
      // backgroundColor: theme.colorText,
    },
    "&:hover $title, &:hover $arrow": {
      color: theme.colorBackground,
    },
    "&:hover $description": {
      color: theme.colorSecondary
    },
    "&:hover $arrow": {
      transform: "rotate(320deg)",
      filter: "brightness(0) saturate(100%) invert(5%) sepia(94%) saturate(2597%) hue-rotate(204deg) brightness(94%) contrast(99%)"
    },
    "&:hover $descriptionText, &:hover $separator": {
      opacity: "100",
    },
    "@media(max-width:750px)": {
      flexDirection: "column",
      gap: "1em",
      "&:hover": {
        padding: "2em 1em",
        backgroundColor: theme.colorTextOpacity80,
      },
    }
  },
  description: {
    // display: "flex",
    fontFamily: "Source Sans",
    color: theme.colorText,
    fontSize: "1.75rem",
  },
  descriptionText: {
    opacity: "0",
    "@media(max-width:750px)": {
      opacity: "100",
    }
  },
  separator: {
    opacity: "0",
    "@media(max-width:750px)": {
      opacity: "100",
    }
  },
  title: {
    fontFamily: "Bebas",
    fontSize: "2rem",
    color: theme.colorPrimary,
  },
  arrow: {
    transition: "transform .5s ease-in-out",
    maxHeight: "20px",
    fontSize: "2.5rem",
    color: theme.colorPrimary
  }
}));

function SingleServicePreviewServicesPage(props) {
  const classes = useStyles()
  return(
    <Link to={`/${props.slug}`}>
      <motion.div
      className={classes.mainContainer}    
      initial={{ translateX: -100, opacity: 0 }}
      whileInView={{ translateX: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1 * props.delay, duration: 0.25}}
      >
        <p className={classes.description}><span className={classes.title}>{props.title} <span className={classes.separator}>—</span></span> <span className={classes.descriptionText}>{props.description}</span></p>
        <img className={classes.arrow} src={arrow} />
      </motion.div>
    </Link>
  );
}

export default SingleServicePreviewServicesPage;