import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "4em",
    marginBottom: "4em",
    maxWidth: "1750px",
    color: theme.colorPrimary,
  },
  leftContainer: {
    height: "4px",
    background: theme.colorSecondary,
    marginRight: "1em",
    width: "100%",
    "@media (max-width: 750px)": {
      width: "15%"
    },
  },
  rightContainer: {
    height: "4px",
    width: "100%",
    background: theme.colorSecondary,
    marginLeft: "1em",
    "@media (max-width: 750px)": {
      width: "15%"
    },
  },
  header: {
    display: "flex",
    fontSize: "2rem",
    whiteSpace: "nowrap",
    fontFamily: "Bebas",
    fontWeight: "bolder",
    textAlign: "center",
    "@media (max-width: 750px)": {
      fontSize: "1.5rem",
      whiteSpace: "normal",
    },
  },
}));

function SectionHeader(props) {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      initial={{ opacity: 0, translateY: -100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      viewport={{once:true}}
      style={props.invis ? {display: "none"} : {}}
    >
      <div className={classes.leftContainer}></div>
      <h1 className={classes.header}>{props.header}</h1>
      <div className={classes.rightContainer}></div>
    </motion.div>
  );
}

export default SectionHeader;
