import React, { useEffect, useCallback, useState, Fragment } from "react";
import { createUseStyles, useTheme } from "react-jss";
import heroPic from "../975.svg";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import BackgroundText from "./BackgroundText";
// import {ReactComponent as Logo} from "../971.svg";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    height: "calc(83.4vh - 84.75px)",
    // heigh
    background: "transparent",
    // background: "orange",
    marginBottom: "2em",
    marginTop: "calc(187px + 2em)",
    display: "flex",
    flexDirection: "row",
    maxWidth: "1750px",
    "@media(max-width:750px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      marginBottom: "0px",
      marginTop: "2em",
    }
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    // background: "red",
    "@media(max-width:750px)": {
      width: "100%",
    }
  },
  leftTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    // background: "white",
    "@media(max-width:750px)": {
      width: "80%",
      height: "100vh",
      position: "absolute",
      zIndex: "-1",
      alignItems: "center",
      opacity:"50%",
    }
  },
  heroPic: {
    "@media(max-width:750px)": {
      opacity: "50%",
    }
  },
  header: {
    color: theme.colorText,
    fontSize: "6rem",
    fontFamily: "Bebas",
    // maxWidth: "700px",
    "@media(max-width:750px)": {
      fontSize:"3rem",
    }
  },
  button: {
    fontFamily: "Bebas",
    fontSize: "1.5rem",
    color: theme.colorPrimary,
    background: theme.colorSecondary,
    padding: "1em",
    borderRadius: "2em",
    cursor: "pointer",
  },
  buttonContainer: {
    "@media (max-width:750px)": {
      display: "flex",
      zIndex: "1",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    }
    // marginTop: "1em",
  },
  highlightedWord: {
    display: "inline-block",
    color: theme.colorPrimary,
  },
  subText: {
    fontFamily: "Source Sans",
    lineHeight: "1.25em",
    color: theme.colorText,
    fontSize: "1.85rem",
    paddingRight: "2em",
    marginTop: "1em",
    marginBottom: "1em",
    "@media (max-width:750px)": {
      paddingRight: "0",
      fontSize: "1.5rem"
    }
  },
}));


function HeroSection() {
  const classes = useStyles();
  const themeImported = useTheme();

  const words = [
    "Elevate",
    "Uplift",
    "Enrich",
    "Empower",
    "Enhance",
    "Heighten",
    "Fortify",
    "Refine",
    "Optimize",
    "Perfect",
    "Boost",
    "Improve",
    "Advance",
    "Strengthen",
    "Develop",
    "Fortify",
  ];
  const [currentWord, setCurrentWord] = useState([words[0]]);
  const [lastWord, setLastWord] = useState([words[0]]);

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * words.length);
    setLastWord(currentWord)
    if(lastWord === currentWord) {
      index === words.length - 1 ? index = 0 : index++;
    }
    setCurrentWord(words[index]);
  }, []);

  useEffect(() => {
    const wordInterval = setInterval(shuffle, 5000);
    return () => clearInterval(wordInterval);
  }, [shuffle]);


  return (
    <Fragment>
      <BackgroundText />
      <div className={classes.mainContainer}>
        <motion.div
          className={classes.leftContainer}
          initial={{ opacity: 0, translateX: -100 }}
          whileInView={{ opacity: 1, translateX: 0 }}
          viewport={{once:true}}
        >
      
          <div className={classes.leftTextContainer}>
            <h1 className={classes.header}>
            <AnimatePresence>

              <motion.span
                key={currentWord}
                className={classes.highlightedWord}
                initial={{ opacity: 0, translateY: -50 }}
                animate={{ opacity: 100, translateY: 0 }}
                exit={{translateY: 100, opacity: 0, color: "#4A4A4A", position: "absolute"}}
                transition={{ duration: 2}}
              >
                {currentWord}
              </motion.span>
            </AnimatePresence>
              {" "}
              your online presence with our innovative web solutions.
            </h1>
            <p className={classes.subText}>
            Advance, Grow, Succeed: Experience an effortless digital transition with us, delivering robust, scalable solutions that are built for your growth and designed to make your business stand out.
            </p>
            <div className={classes.buttonContainer}>
              <Link to="/contact">
                <button className="button-52">Get Started</button>
              </Link>
            </div>
          </div>
        </motion.div>
        <motion.div
          className={classes.rightContainer}
          initial={{ opacity: 0, translateX: 100 }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{ x: { duration: 1 }, default: { ease: "linear" } }}
        >
          <img className={classes.heroPic} src={heroPic} alt="Illustration of a laptop and coffee" />
        </motion.div>
      </div>
    </Fragment>
  );
}

export default HeroSection;
