import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    position: "fixed",
    // paddingTop: "1rem",
    paddingBottom: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "30rem",
    textAlign: "center",
    width: "100%",
    height: "100%",
    zIndex: "-100",
    color: "transparent",
    textStroke: `3px ${theme.colorPrimary}`,
    opacity: "8%",
    overflow: "hidden",
  }
}));

function BackgroundText() {
  const classes = useStyles()
  return(
    <h3 className={classes.mainContainer}>
      STUDIO WHITE TIGER
    </h3>

  );
}

export default BackgroundText;