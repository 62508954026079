import React from "react";
import { createUseStyles } from "react-jss";
import ProgrammingLanguage from "./ProgrammingLanguage";
import reactSVG from "../pics/react-js.svg"
import nextjsSVG from "../pics/next-js.svg"
import nodejsSVG from "../pics/node.svg"
import wordpressSVG from "../pics/wordpress.svg"
import postgresSVG from "../pics/postgresql.svg"
import expressSVG from "../pics/express.svg"
import awsSVG from "../pics/aws.svg"
import strapiSVG from "../pics/strapi.svg"
import linodeSVG from "../pics/linode.svg"
import reduxSVG from "../pics/redux.svg"
import shopifySVG from "../pics/shopify.svg"
import { motion } from "framer-motion";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    gap: "1em",
    marginBottom: "4em",
    // flexGrow: "1",

  }
});

function ProgrammingLanguageContainer() {
  const classes = useStyles()
  return(
    <motion.div 
      className={classes.mainContainer}
      initial={{ translateY: -100, opacity: 0 }}
      whileInView={{ translateY: 0, opacity: 100 }}
      transition={{delay: .25}}
      viewport={{ once: true }}
    >
      <ProgrammingLanguage title="React.js" link="https://reactjs.org/" image={reactSVG} />
      <ProgrammingLanguage title="Next.js" link="https://nextjs.org/docs" image={nextjsSVG} />
      <ProgrammingLanguage title="Express.js" link="https://expressjs.com/" image={expressSVG} />
      <ProgrammingLanguage title="Node.js" link="https://nodejs.org/" image={nodejsSVG} />
      <ProgrammingLanguage title="Redux" link="https://redux.js.org/" image={reduxSVG} />
      <ProgrammingLanguage title="PostgreSQL" link="https://www.postgresql.org/" image={postgresSVG} />
      <ProgrammingLanguage title="React Native" link="https://reactnative.dev/" image={reactSVG} />
      <ProgrammingLanguage title="Wordpress" link="https://wordpress.org" image={wordpressSVG} />
      <ProgrammingLanguage title="Shopify" link="https://www.shopify.com/" image={shopifySVG} />
      <ProgrammingLanguage title="Strapi" link="https://strapi.io/" image={strapiSVG} />
      <ProgrammingLanguage title="AWS" link="https://aws.amazon.com/" image={awsSVG} />
      <ProgrammingLanguage title="Linode" link="https://www.linode.com/" image={linodeSVG} />
    </motion.div>
    

  );
}

export default ProgrammingLanguageContainer;